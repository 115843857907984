import dayjs from "dayjs"; // Import dayjs
import { useMutation, useQueryClient } from "react-query";
import { axios } from "../../../lib/axios";
import { useNotificationStore } from "../../../stores/notifications";

export const usePausePlan = () => {
  const { addNotification } = useNotificationStore();
  const queryClient = useQueryClient();

  return useMutation(
    (date?: string) => {
      const resumeDate = date
        ? dayjs(date).unix()
        : dayjs().add(30, "day").unix();
      return axios.post("/stripe/pauseSubscription", {
        resumes_at: resumeDate,
      });
    },
    {
      onSuccess: () => {
        addNotification({
          type: "success",
          title: "Subscription paused",
          message: "Your subscription has been successfully paused.",
        });
        queryClient.invalidateQueries("subscription");
      },
    }
  );
};
