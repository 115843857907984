import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  PasteAssetButton,
  ViewAssetButton,
} from "@/components/Elements";
import { useHandlePasteContent } from "@/features/documents/utils/pasting";
import { useState } from "react";

export function QuestionAsset({
  headerText,
  assetText,
  collapseDisabled = false,
}) {
  const handlePasteContent = useHandlePasteContent();
  const [assetsVisible, setAssetsVisible] = useState(false);

  return (
    <Collapsible
      className="group/asset relative mt-2 px-2 py-1 text-2xs bg-white rounded-md ring-1 ring-inset ring-zinc-900/7.5  dark:ring-white/10 hover:shadow-zinc-900/5 dark:bg-white/2.5 dark:hover:shadow-zinc-50/5"
      onOpenChange={() => setAssetsVisible(!assetsVisible)}
      open={assetsVisible}
    >
      <div className="flex group">
        <CollapsibleTrigger
          className="w-full text-start flex items-center text-zinc-900 justify-between"
          onClick={(event) => event.stopPropagation()}
          disabled={collapseDisabled}
        >
          <div className="flex items-center justify-start w-full text-start text-zinc-900">
            <span className="dark:text-zinc-50 ml-2 self-center">
              {headerText}
            </span>
          </div>
          {!collapseDisabled && (
            <ViewAssetButton assetsVisible={assetsVisible} />
          )}
        </CollapsibleTrigger>
        <PasteAssetButton
          handlePasteContent={handlePasteContent}
          headerText={headerText}
          handlePasteCitation={undefined}
          url={undefined}
        />
      </div>
      <CollapsibleContent>
        {assetText &&
          assetText.map((text, index) => (
            <p
              key={index}
              className="px-2 mt-2 mr-4 text-2xs text-zinc-900 dark:text-white"
            >
              {text}
            </p>
          ))}
      </CollapsibleContent>
    </Collapsible>
  );
}
