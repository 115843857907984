import { Progress } from "@/components/Elements";
import { sortByTopicScore } from "@/features/documents/utils/topics";
import { useDocumentStore } from "@/stores/document";
import { useEditorStore } from "@/stores/editor";
import { useSerpStore } from "@/stores/serp";
import { useTopicsStore } from "@/stores/topics";
import { ArrowUpCircleIcon } from "@heroicons/react/24/outline";
import { useEffect } from "react";
import { useHeaderNavigation } from "../../hooks/useHeaderNavigation";
import { CompetitorCard } from "./CompetitorCard";

export default function TopicCompetitors({ setCompetitorsVisible }) {
  const { document } = useDocumentStore();
  const { serp } = useSerpStore();
  const { editor } = useEditorStore();
  const { topics: topicsStore } = useTopicsStore();
  const { articles } = serp[document.id] || {
    articles: [],
  };
  const { score, avgScore } = topicsStore[document.id] || {
    score: 0,
    avgScore: 0,
  };
  const { setHeaderNavigation } = useHeaderNavigation();

  const { title, wordCount } = editor;
  const blacklist = document.metadata?.blacklist || {};

  const validArticles = (articles || [])
    .filter((article) => article.isValid && !blacklist[article.url])
    .sort((a, b) => {
      // Place custom import articles at the beginning
      if (a.isCustomImport && !b.isCustomImport) return -1;
      if (!a.isCustomImport && b.isCustomImport) return 1;
      return a.index - b.index;
    })
    .map((article, index, array) => {
      // Calculate rank only for non-custom import articles
      const rank =
        array
          .slice(0, index)
          .filter((prevArticle) => !prevArticle.isCustomImport).length + 1;
      return {
        ...article,
        rank: article.isCustomImport ? null : rank,
      };
    });

  let competitors = [...validArticles];
  const userDoc = {
    title: title,
    topic_score: score,
    url: document.url || "",
    word_count: wordCount,
    isUserDoc: true,
  };
  competitors.push(userDoc);
  competitors = competitors.sort(sortByTopicScore);

  useEffect(() => {
    setHeaderNavigation({
      title: "Topic Competitors",
      onBack: () => setCompetitorsVisible(false),
    });
  }, [setCompetitorsVisible]);

  const renderScore = () => {
    return (
      <div className="flex-col w-full bg-zinc-50 dark:bg-zinc-900 p-4 rounded-md border dark:border-zinc-800">
        <div className="flex">
          <div className="flex-col">
            <h1 className="text-xs font-semibold text-zinc-800 dark:text-zinc-200">
              Your document rank
            </h1>
            <span className="text-xs font-semibold text-zinc-500 mr-0.5 dark:text-zinc-300">
              #
            </span>
            <span className="text-xl font-semibold text-zinc-800 dark:text-zinc-200">
              {competitors.findIndex((article) => article.isUserDoc) + 1}
            </span>
            <span className="text-xs font-semibold text-zinc-500 ml-1">
              out of {competitors.length}
            </span>
          </div>
        </div>
        <Progress
          progress={score / 10}
          className={`mt-2`}
          stripColor={"emerald"}
          averageProgress={avgScore}
        />
        <p className="relative text-zinc-700 font-medium dark:text-emerald-300 text-xs flex items-center mt-2">
          Average topic score is <b className="mx-1">{Math.floor(avgScore)}%</b>{" "}
          across competitors
          <ArrowUpCircleIcon className="h-4 w-4 ml-1" />
        </p>
      </div>
    );
  };

  return (
    <div className="px-4 space-y-2 pb-20">
      {renderScore()}
      {competitors.map((article, index) => {
        const links = article.links ? article.links.length : 0;
        const urlHost = article.url
          ? new URL(article.url).hostname.replace("www.", "")
          : "";

        return (
          <CompetitorCard
            key={index}
            title={article.title}
            wordCount={article.word_count}
            url={article.url}
            urlHost={urlHost}
            score={article.topic_score}
            rank={index + 1}
            isUserDoc={article.isUserDoc}
            domainAuthority={article.domainAuthority}
            links={links}
            isCustomImport={article.isCustomImport}
            topicScore={article.topic_score}
            assets={article.assets}
            averageScore={avgScore}
          />
        );
      })}
    </div>
  );
}
