import { useEditorStore } from "@/stores/editor";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useMemo } from "react";

const debounce = (
  func: { ({ editorState }: { editorState: any }): void; apply?: any },
  wait: number | undefined
) => {
  let timeout: string | number | NodeJS.Timeout | undefined;
  return (...args: any) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};

export default function OnChangePlugin() {
  const [editor] = useLexicalComposerContext();
  const { setEditor } = useEditorStore();
  const debounceWaitTime = 500; // Adjust this value for the debounce wait time

  const updateEditorState = useMemo(
    () =>
      debounce(({ editorState }) => {
        const nodes = editorState.toJSON().root.children;
        const headerNodes: any[] = [];
        const textNodes: any[] = [];
        const linkNodes: any[] = [];
        const imageNodes: any[] = [];
        for (const node of nodes) {
          if (node.type === "heading") {
            headerNodes.push(node.children?.[0]?.text ?? "");
          } else {
            if (node.type === "horizontalrule") {
              textNodes.push("---");
              continue;
            }

            for (const child of node.children) {
              if (child.type === "link") {
                linkNodes.push({
                  text: child.children[0]?.text ?? "",
                  url: child.url,
                });
              }

              if (child.type === "image") {
                imageNodes.push({
                  src: child.src,
                });
              }

              if (child.type === "mark") {
                textNodes.push(child.children[0]?.text ?? "");
              }

              if (child.type === "text") {
                textNodes.push(child.text);
              }
            }
          }
        }
        setEditor((editorState) => {
          editorState.headerNodes = headerNodes;
          editorState.textNodes = textNodes;
          editorState.linkNodes = linkNodes;
          editorState.imageNodes = imageNodes;
        });
      }, debounceWaitTime),
    [setEditor]
  );

  editor.registerUpdateListener(updateEditorState);

  return null;
}
