import { ContentLayout } from "@/components/Layout";

export const AiTools = () => {
  return (
    <ContentLayout>
      <div className="flex flex-col items-center justify-center h-full">
        <h3 className="mt-4 text-lg text-center text-muted-foreground opacity-50 dark:text-white">
          This feature is in progress. Please check back later.
        </h3>
      </div>
    </ContentLayout>
  );
};
