import { Button, Progress, Tag } from "@/components/Elements";
import { useHandlePasteContent } from "@/features/documents/utils/pasting";
import { useEditorStore } from "@/stores/editor";
import { CircleBackslashIcon } from "@radix-ui/react-icons";
import { Copy } from "lucide-react";
import { useUpdateDocument } from "../../../../features/documents/api/updateDocument";
import { useDocumentStore } from "../../../../stores/document";

export const TopicCard = ({
  sources,
  topic,
  count,
  total,
  setSelectedTopic,
  item,
  color,
}) => {
  const handlePasteContent = useHandlePasteContent();
  const { document } = useDocumentStore();
  const { setEditor } = useEditorStore();
  const updateDocumentMutation = useUpdateDocument({ notifyOnSuccess: false });

  const handleUpdateDocumentBlacklist = () => {
    const updatedDocument = {
      ...document,
      metadata: {
        ...document.metadata,
        blacklist_topics: {
          ...document.metadata.blacklist_topics,
          [topic]: true,
        },
      },
    };

    updateDocumentMutation.mutateAsync(updatedDocument);
  };

  return (
    <div
      onClick={() => {
        setSelectedTopic(item);
        setEditor((editorState) => {
          editorState.optimizeViewVisible = false;
        });
      }}
      className="group p-2 py-2.5 cursor-pointer relative items-center rounded-md bg-white transition-shadow hover:shadow-md hover:shadow-zinc-900/5 dark:bg-white/2.5 dark:hover:shadow-black/5 hover:bg-white"
    >
      <div className="absolute inset-0 rounded-md ring-1 ring-inset ring-zinc-900/7.5 group-hover:ring-zinc-900/10 dark:ring-white/10 dark:group-hover:ring-white/20" />
      <div className="flex justify-between">
        <div className="flex items-start h-full">
          <Tag
            variant="medium"
            className="left-3 h-[34px] min-w-[58px]"
            color={color}
            textClassName="font-bold mx-auto"
          >
            <span className="text-2xs">{`${count} / ${total}`}</span>
          </Tag>

          <div className="flex flex-col space-y-[3px]">
            <p className="text-xs leading-tight font-medium dark:text-zinc-50 pl-2">
              {topic}
            </p>
            <p className="text-2xs leading-tight font-normal text-gray-400 dark:text-zinc-50 pl-2">
              {item.item_count} {item.item_count > 1 ? "sources" : "source"}
            </p>
          </div>
        </div>

        <div className="flex items-center">
          <Button
            variant="buttonIcon"
            className="mr-1 invisible group-hover:visible"
            buttonIcon={<CircleBackslashIcon />}
            tooltipContent={`Add to blacklist`}
            onClick={(event) => {
              event.stopPropagation();
              handleUpdateDocumentBlacklist();
            }}
          />
          <Button
            variant="buttonIcon"
            className="mr-1 invisible group-hover:visible"
            buttonIcon={<Copy />}
            tooltipContent={`Paste to editor`}
            onClick={(event) => {
              handlePasteContent(topic);
              event.stopPropagation();
            }}
          />
        </div>
      </div>
      <Progress
        progress={(count / total) * 10}
        className={`mt-2`}
        stripColor={color}
      />
    </div>
  );
};
