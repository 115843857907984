import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
} from "@/components/Elements";
import { useDocumentStore } from "@/stores/document";
import { useEditorStore } from "@/stores/editor";
import { DialogPortal } from "@radix-ui/react-dialog";
import dayjs from "dayjs";
import { useState } from "react";
import { TbAlertTriangleFilled, TbArrowRight } from "react-icons/tb";
import { getDocument } from "../api/getDocument";
import { useUpdateDocument } from "../api/updateDocument";

export const EditorConflictOnLoadDialog = ({
  open,
  setOpen,
  lastEditorFullName,
  lastEditedTime,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  lastEditorFullName: string;
  lastEditedTime: number;
}) => {
  const { document: fraseDocument } = useDocumentStore();
  const { setEditor } = useEditorStore();

  const updateDocumentMutation = useUpdateDocument({
    notifyOnSuccess: false,
    isResolvingConflict: true,
  });
  const [isResolvingConflict, setIsResolvingConflict] = useState(false);

  const body = (
    <div className="flex text-zinc-600 dark:text-zinc-400 items-center">
      <TbAlertTriangleFilled className="mr-2" />
      This document was last edited by{" "}
      <p className="mx-1 font-medium">
        {lastEditorFullName ? lastEditorFullName : "another user"}{" "}
      </p>
      {dayjs(lastEditedTime).fromNow()}
    </div>
  );

  const handleOpenChange = (open) => {
    setOpen(open);
    if (open) {
      // Reset the state when the dialog is opened
    }
  };

  const handleContinueEditing = async () => {
    setIsResolvingConflict(true);

    // get the latest document
    const refreshedFraseDocument = await getDocument({
      documentHash: fraseDocument.hash,
    });
    // check if the document has been edited by another user
    await updateDocumentMutation.mutateAsync(refreshedFraseDocument);
    setIsResolvingConflict(false);
    setEditor((editorState) => {
      editorState.isRefreshingDocument = true;
    });
  };

  const confirmButton = (
    <Button
      variant="primaryBlur"
      onClick={() => {
        handleContinueEditing();
        setOpen(false);
      }}
      endIcon={<TbArrowRight />}
      isLoading={isResolvingConflict}
    >
      Continue editing
    </Button>
  );

  return (
    <Dialog onOpenChange={handleOpenChange} open={open} modal={true}>
      <DialogPortal>
        <DialogContent
          preventCloseOnEscape={true}
          onPointerDownOutside={(e) => {
            e.preventDefault();
          }}
        >
          <DialogHeader className="pb-0 flex items-center justify-between"></DialogHeader>
          <DialogDescription className="px-4">{body}</DialogDescription>
          <DialogFooter className="px-4 pb-4">{confirmButton}</DialogFooter>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};
