import { axios } from "@/lib/axios";
import { useQuery } from "react-query";

type GetGptTitlePayload = {
  query: string;
  lang: string;
  serp: Array<{
    title: string;
    url: string;
    snippet: string;
    author: string;
    date_created: string;
    image: string;
  }>;
  docHash: string;
};

export const getGptTitle = ({
  query,
  lang,
  serp,
  docHash,
}: GetGptTitlePayload): Promise<any> => {
  return axios.post(`/gpt_title`, {
    query,
    lang,
    serp,
    docHash,
  });
};

type UseGetGptTitleOptions = {
  query: string;
  lang: string;
  serp: Array<{
    title: string;
    url: string;
    snippet: string;
    author: string;
    date_created: string;
    image: string;
  }>;
  docHash: string;
  config?: any;
};

export const useGetGptTitle = ({
  query,
  lang,
  serp,
  docHash,
  config,
}: UseGetGptTitleOptions) => {
  return useQuery({
    ...config,
    queryKey: ["gptTitle", { query, lang, serp, docHash }],
    queryFn: () => getGptTitle({ query, lang, serp, docHash }),
  });
};
