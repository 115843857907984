import {
  Button,
  Command,
  CommandGroup,
  CommandItem,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/Elements";
import { useHandlePasteHeadings } from "@/features/documents/utils/pasting";
import { ClipboardIcon } from "@radix-ui/react-icons";
import { TbDots, TbWorld } from "react-icons/tb";

const options = [
  {
    value: "pasteOutline",
    label: "Paste Headings",
    icon: <ClipboardIcon className="mr-2 w-3 h-3" />,
    onClick: (url, assets, handlePasteHeadings) => {
      handlePasteHeadings(
        assets.map((asset) => {
          return {
            header: asset.header,
            user_header_tag: asset.header_tag,
            url: asset.url,
          };
        })
      );
    },
  },
  {
    value: "openPage",
    label: "Open Page",
    icon: <TbWorld className="mr-2 w-3 h-3" />,
    onClick: (url) => {
      window.open(url, "_blank");
    },
  },
];

export const SerpMenu = ({ url, assets }: { url: string; assets: [] }) => {
  const handlePasteHeadings = useHandlePasteHeadings();

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="floatingIcon"
          size="sm"
          className="absolute top-1.5 right-0.5 py-1 px-1 z-10 invisible group-hover:visible"
          buttonIcon={<TbDots />}
        ></Button>
      </PopoverTrigger>
      <PopoverContent>
        <Command>
          <CommandGroup>
            {options.map((option, index) => (
              <CommandItem
                key={index}
                onSelect={() =>
                  option.onClick(url, assets, handlePasteHeadings)
                }
              >
                {option.icon}
                {option.label}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
