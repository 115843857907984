// src/features/settings/components/ConfirmCancelSubscriptionDialog.tsx
import {
  Button,
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/Elements";
import { useTrackEvent } from "@/features/analytics/api/trackUser";
import { DialogClose, DialogPortal } from "@radix-ui/react-dialog";
import React, { useState } from "react";
import { InputLabel } from "../../../components/Elements";
import { useDeletePlan } from "../../subscription/api/deletePlan";
import { usePausePlan } from "../../subscription/api/pausePlan";

export const ConfirmCancelSubscriptionDialog: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [selectedReason, setSelectedReason] = useState<string | null>(null);
  const cancelSubscriptionMutation = useDeletePlan();
  const pauseSubscriptionMutation = usePausePlan();
  const trackEvent = useTrackEvent();

  const handleTrackCancellation = async () => {
    trackEvent.mutate({
      event: "customer_cancellation",
      properties: JSON.stringify({}),
    });
  };

  const handleCancelSubscription = () => {
    cancelSubscriptionMutation.mutate(undefined, {
      onSuccess: () => {
        handleTrackCancellation();
        setOpen(false);
        // Add any additional logic you need to handle after successful cancellation
      },
    });
  };

  const handlePauseSubscription = () => {
    pauseSubscriptionMutation.mutate(undefined, {
      onSuccess: () => {
        setOpen(false);
        // Add any additional logic you need to handle after successful pause
      },
    });
  };

  const renderCancellationReasons = () => (
    <div className="space-y-4">
      <div>
        <InputLabel label="We are sorry to see you go 😢"></InputLabel>
        <p>
          Please help us improve by letting us know why you are cancelling your
          subscription:
        </p>
      </div>
      <ul className="space-y-2">
        <li>
          <Button
            variant="outlineBlur"
            onClick={() => setSelectedReason("Too expensive")}
          >
            Too expensive
          </Button>
        </li>
        <li>
          <Button
            variant="outlineBlur"
            onClick={() => setSelectedReason("Missing features")}
          >
            Missing features
          </Button>
        </li>
        <li>
          <Button
            variant="outlineBlur"
            onClick={() => setSelectedReason("Bugs or support issues")}
          >
            Bugs or support issues
          </Button>
        </li>
        <li>
          <Button
            variant="outlineBlur"
            onClick={() => setSelectedReason("Not using it enough")}
          >
            Not using it enough
          </Button>
        </li>
      </ul>
    </div>
  );

  const renderPauseOption = () => (
    <div className="space-y-2">
      {/*<InputLabel label="Pause your Subscription for 1 Month"></InputLabel>
      <p>
        Sometimes folks use Frase on a project-by-project basis. You can pause
        your subscription and skip a month of Frase payments. All your settings
        and data will be intact when you return.
      </p>
      <Button
        variant="outlineBlur"
        onClick={handlePauseSubscription}
        isLoading={pauseSubscriptionMutation.isLoading}
      >
        Pause subscription for the next month
  </Button>*/}
      <Button
        variant="destructiveBlur"
        onClick={handleCancelSubscription}
        isLoading={cancelSubscriptionMutation.isLoading}
      >
        Proceed with cancellation
      </Button>
    </div>
  );

  return (
    <Dialog
      open={open}
      onOpenChange={(newOpen) => {
        if (newOpen) {
          setSelectedReason(null);
        }
        setOpen(newOpen);
      }}
    >
      <DialogTrigger asChild>
        <Button
          variant="outlineBlur"
          className="w-fit"
          textClassName="text-red-500"
          size="xs"
        >
          Cancel subscription
        </Button>
      </DialogTrigger>
      <DialogPortal>
        <DialogContent>
          <DialogHeader className="pb-0 flex items-center justify-between">
            <DialogTitle>Confirm subscription cancellation</DialogTitle>
            <DialogCloseButton
              close={() => {
                setOpen(false);
                setSelectedReason(null);
              }}
            />
          </DialogHeader>
          <DialogDescription className="px-4">
            {selectedReason ? renderPauseOption() : renderCancellationReasons()}
          </DialogDescription>
          <DialogFooter className="px-4 pb-4">
            <DialogClose asChild>
              <Button variant="outlineBlur">Back</Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};
