import { axios } from "../../../lib/axios";

export const getContentForUrl = (
  url: string
): Promise<{
  content: string;
  title: string;
  url: string;
}> => {
  return axios.post("/processURL", {
    url: url,
    timeout: 20,
    include_full_text: true,
    skip_cache: true,
  });
};
