import { LinkIcon } from "@heroicons/react/24/solid";
import { $generateNodesFromDOM } from "@lexical/html";
import { $convertFromMarkdownString } from "@lexical/markdown";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";
import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  $insertNodes,
} from "lexical";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import TurndownService from "turndown";
import PlaygroundNodes from "../../../components/AdvancedEditor/nodes/PlaygroundNodes";
import { PLAYGROUND_TRANSFORMERS } from "../../../components/AdvancedEditor/plugins/MarkdownTransformers";
import PlaygroundEditorTheme from "../../../components/AdvancedEditor/themes/PlaygroundEditorTheme";
import {
  sanitizeUrl,
  validateUrl,
} from "../../../components/AdvancedEditor/utils/url";
import {
  Button,
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Input,
  Label,
} from "../../../components/Elements";
import { LogoSpinner } from "../../../components/Elements/Spinner/LogoSpinner";
import { useDocumentStore } from "../../../stores/document";
import { useEditorStore } from "../../../stores/editor";
import { useNotificationStore } from "../../../stores/notifications";
import { cn } from "../../../utils/style";
import { getContentForUrl } from "../../documents/api/processUrl";
import { useUpdateDocument } from "../../documents/api/updateDocument";

const PrepoulateContentPreviewPlugin = ({ html }: { html: string }) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    editor.update(() => {
      const dom = new DOMParser().parseFromString(html, "text/html");
      let nodes = $generateNodesFromDOM(editor, dom);
      $getRoot().select();
      try {
        $insertNodes(nodes);
      } catch (error) {
        // insert as plain text
        const markdown = new TurndownService().turndown(html);
        const paragraphNode = $createParagraphNode();
        const textNode = $createTextNode(markdown);
        paragraphNode.append(textNode);
        $getRoot().append(paragraphNode);

        $convertFromMarkdownString(
          $getRoot().getTextContent(),
          PLAYGROUND_TRANSFORMERS,
          $getRoot()
        );
      }
    });
  }, []);

  return null;
};

function ContentPreviewEditor({
  html,
  title,
  className,
  setPreviewTitle,
}: {
  html: string;
  title?: string;
  className?: string;
  setPreviewTitle?: (title: string) => void;
}) {
  return (
    <div
      className={cn(
        "space-y-4 flex flex-col flex-1 pt-4 px-4 overflow-hidden",
        className
      )}
    >
      <Input
        label="Title"
        value={title}
        onChange={setPreviewTitle}
        placeholder="Title"
        className="w-full text-[16px] font-medium text-zinc-600"
      />

      <div className="flex flex-col space-y-2 flex-1 overflow-hidden">
        <Label className="text-zinc-500">Content body</Label>
        <div className="border-zinc-100 dark:border-zinc-800 border rounded-md p-3 flex-1 overflow-y-scroll">
          <LexicalComposer
            initialConfig={{
              editable: false,
              namespace: "Preview",
              onError: (error: Error) => {
                // console.error(error);
              },
              nodes: [...PlaygroundNodes],
              theme: PlaygroundEditorTheme,
            }}
          >
            <PlainTextPlugin
              contentEditable={<ContentEditable className="h-full" />}
              placeholder={null}
              ErrorBoundary={LexicalErrorBoundary}
            />
            <PrepoulateContentPreviewPlugin html={html} />
          </LexicalComposer>
        </div>
      </div>
    </div>
  );
}

export const ImportContentUrlButton = ({
  open,
  setOpen,
  setCustomUrls,
  trigger,
  shouldSubmit,
  onSubmission,
  setShowPlaceholders,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  setCustomUrls: (urls: string[]) => void;
  trigger?: React.ReactNode;
  shouldSubmit: boolean;
  onSubmission: () => void;
  setShowPlaceholders: (showPlaceholders: boolean) => void;
}) => {
  const [editor] = useLexicalComposerContext();
  const { editor: editorStore } = useEditorStore();
  const { document: fraseDocument } = useDocumentStore();
  const { activeTabIndex } = editorStore;
  const [sanitizedImportUrls, setSanitizedImportUrls] = useState(
    [] as string[]
  );
  const updateDocumentMutation = useUpdateDocument({
    notifyOnSuccess: false,
  });
  const [isProcessingUrl, setIsProcessingUrl] = useState(false);
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const [isInsertingToEditor, setIsInsertingToEditor] = useState(false);
  const [previewTitle, setPreviewTitle] = useState("");
  const [previewHtml, setPreviewHtml] = useState("");
  const { addNotification } = useNotificationStore();
  const location = useLocation();
  const importUrlFromState = location.state?.importUrl;
  const documentUrl = fraseDocument.metadata?.url || "";

  const [importUrls, setImportUrls] = useState(documentUrl);

  const hasSubmitted = useRef(false);

  const handleBack = () => {
    setIsPreviewVisible(false);
    setPreviewTitle("");
    setPreviewHtml("");
  };

  const handleUpdateDocumentTitle = async () => {
    const updatedDocument = {
      ...fraseDocument,
      text: fraseDocument.text.map((text, index) =>
        index === activeTabIndex ? { ...text, title: previewTitle } : text
      ),
      metadata: {
        ...fraseDocument.metadata,
        import_content_url: sanitizedImportUrls[0] || undefined,
      },
    };

    await updateDocumentMutation.mutateAsync(updatedDocument);
  };

  const handleUpdateDocumentMetadataUrl = async (url: string) => {
    const updatedDocument = {
      ...fraseDocument,
      metadata: {
        ...fraseDocument.metadata,
        url: url,
      },
    };

    await updateDocumentMutation.mutateAsync(updatedDocument);
  };

  const handleInsertToEditor = async () => {
    setIsInsertingToEditor(true);

    await handleUpdateDocumentTitle();
    await handleUpdateDocumentMetadataUrl(sanitizedImportUrls[0]);

    editor.update(() => {
      const dom = new DOMParser().parseFromString(previewHtml, "text/html");
      let nodes = $generateNodesFromDOM(editor, dom);
      const root = $getRoot();
      root.select();
      root.clear();

      try {
        $insertNodes(nodes);
      } catch (error) {
        const markdown = new TurndownService().turndown(previewHtml);
        const paragraphNode = $createParagraphNode();
        const textNode = $createTextNode(markdown);
        paragraphNode.append(textNode);
        $insertNodes([paragraphNode]);

        $convertFromMarkdownString(
          root.getTextContent(),
          PLAYGROUND_TRANSFORMERS,
          root
        );
      }
      root.selectStart();
    });

    setShowPlaceholders(false);
    setIsInsertingToEditor(false);
    addNotification({
      type: "success",
      title: `Imported content from URL`,
      message: (
        <span className="flex items-center">
          <LinkIcon className="w-3.5 h-3.5" />
          <span className="ml-2 text-xs">
            {sanitizedImportUrls[0] || "Untitled"}
          </span>
        </span>
      ),
    });
    setOpen(false);
  };

  const handleSubmit = async (documentUrl?: string) => {
    setIsProcessingUrl(true);
    const processedUrls = documentUrl
      ? [sanitizeUrl(documentUrl.trim())]
      : importUrls.split(",").map((url) => sanitizeUrl(url.trim()));
    let valid = true;
    let invalidUrls = [] as string[];
    processedUrls.forEach((url) => {
      if (!validateUrl(url)) {
        valid = false;
        invalidUrls.push(url);
      }
      url = sanitizeUrl(url);
    });

    if (!valid) {
      addNotification({
        title: "Invalid URL",
        message: `The following URLs are invalid: ${invalidUrls.join(
          ", "
        )}. The supported protocols are https and http.`,
        type: "error",
      });
      setIsProcessingUrl(false);
      return;
    } else {
      setSanitizedImportUrls(processedUrls);
    }

    getContentForUrl(processedUrls[0])
      .then((data) => {
        if (data !== "error" && data && data.assets && data.assets.length > 0) {
          const title = data.title || "";
          let html = "";
          data.assets.forEach((asset) => {
            if (asset.header && asset.header_tag !== "title") {
              if (asset.header_tag !== "h1") {
                html += `<${asset.header_tag}>${asset.header}</${asset.header_tag}>`;
              }
              asset.html.forEach((htmlPart) => {
                html += htmlPart;
              });
            }
          });

          setPreviewTitle(title);
          setPreviewHtml(html);
          setIsPreviewVisible(true);
        } else {
          addNotification({
            title: "Content Extraction Error",
            message:
              "Sorry, we were not able to automatically extract content from this page. Alternatively, you can also copy/paste the content.",
            type: "error",
          });
        }
        setIsProcessingUrl(false);
      })
      .catch((error) => {
        addNotification({
          title: "Content Extraction Error",
          message:
            "Sorry, we were not able to automatically extract content from this page. Alternatively, you can also copy/paste the content.",
          type: "error",
        });
        setIsProcessingUrl(false);
      });
  };

  useEffect(() => {
    if (
      !isProcessingUrl &&
      !previewHtml &&
      importUrlFromState &&
      !open &&
      !hasSubmitted.current
    ) {
      setOpen(true);
      handleSubmit(importUrlFromState);
      hasSubmitted.current = true;
    }
  }, [
    importUrlFromState,
    setOpen,
    handleSubmit,
    isProcessingUrl,
    previewHtml,
    open,
  ]);

  useEffect(() => {
    if (shouldSubmit) {
      handleSubmit(documentUrl);
      onSubmission();
    }
  }, [shouldSubmit, documentUrl, onSubmission]);

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        if (!isProcessingUrl) {
          setOpen(open);
          setImportUrls(documentUrl);
          setPreviewTitle("");
          setPreviewHtml("");
          setIsProcessingUrl(false);
          setIsPreviewVisible(false);
        }
      }}
    >
      <DialogTrigger asChild>
        {trigger || (
          <Button variant="outlineBlur" size="xs" className="h-7">
            Import URL
          </Button>
        )}
      </DialogTrigger>
      <DialogContent
        className={cn(
          isPreviewVisible &&
            "w-[780px] h-[calc(80vh)] rounded-md m-4 overflow-hidden"
        )}
        variant={isPreviewVisible ? "fullScreen" : "fitContent"}
      >
        {isProcessingUrl ? (
          <div className="flex items-center justify-center py-16 pb-24">
            <LogoSpinner
              variant="md"
              loadingText="Importing content from URL..."
            ></LogoSpinner>
          </div>
        ) : isPreviewVisible ? (
          <>
            <DialogHeader className="pb-0 flex items-center justify-between">
              <DialogTitle>Imported content preview</DialogTitle>
              <DialogCloseButton
                close={() => {
                  setOpen(false);
                }}
              />
            </DialogHeader>
            <ContentPreviewEditor
              html={previewHtml}
              title={previewTitle}
              setPreviewTitle={setPreviewTitle}
            />
            <DialogFooter className="px-4 pb-4 text-center flex-col pt-4">
              <Button variant="outlineBlur" onClick={handleBack}>
                Back
              </Button>
              <Button
                variant="primaryBlur"
                onClick={() => {
                  handleInsertToEditor();
                }}
                isLoading={isInsertingToEditor}
              >
                Insert content
              </Button>
            </DialogFooter>
          </>
        ) : (
          <>
            <DialogHeader className="pb-0 flex items-center justify-between">
              <DialogTitle>Import content from URL</DialogTitle>
              <DialogCloseButton
                close={() => {
                  setOpen(false);
                }}
              />
            </DialogHeader>
            <DialogDescription className="overflow-y-hidden px-4 space-y-4">
              <Input
                label="Import URL"
                value={importUrls}
                onChange={setImportUrls}
                placeholder="https://www.example1.com"
                onClick={(e) => e.stopPropagation()}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit();
                  }
                }}
              />
            </DialogDescription>

            <DialogFooter className="px-4 pb-4 text-center flex-col">
              <Button
                variant="outlineBlur"
                onClick={() => {
                  setOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="primaryBlur"
                disabled={importUrls.length === 0}
                onClick={() => handleSubmit()}
              >
                Import URL
              </Button>
            </DialogFooter>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};
