import { CancelToken, axiosService } from "@/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";
import { useSerpStore } from "@/stores/serp";
import { useTopicsStore } from "@/stores/topics";
import { useQuery } from "react-query";
import { FraseDocument } from "../types";
import {
  getArticleCounts,
  getHeadings,
  getQuestions,
  validateArticles,
} from "../utils/research";
import { processBrief } from "../utils/serp";
import {
  filterClusters,
  filterTopics,
  scoreSERP,
  scoreTopics,
} from "../utils/topics";

export interface UrlBatchResult {
  items: Article[];
  cluster_info: Cluster[];
}
export interface Article {
  domainAuthority: number;
  assets: Asset[];
  author: string;
  clean_text: string;
  date: string;
  description: string;
  entities: ArticleEntity[];
  error_msg: string;
  html: string;
  images: string[];
  language: string;
  links: string[];
  pdf_asset: boolean;
  properties: {
    how_to: boolean;
    listicle: boolean;
    long_form: boolean;
    product: boolean;
    review: boolean;
    short_form: boolean;
    visual: boolean;
  };
  questions: string[];
  statistics: string[];
  title: string;
  topic_score: number;
  topic_score_map: Record<
    string,
    {
      count: number;
      status: string;
      color: string;
      bg_color: string;
    }
  >;
  raw_topic_score?: number;
  url: string;
  videos: string[];
  word_count: number;
  isCustomImport?: boolean;
}

export interface ArticleEntity {
  count: number;
  entity: string;
  plural_lower: string;
  pos: string;
  sing_lower: string;
  type: string;
}

export interface Asset {
  header: string;
  header_tag: string;
  html: string[];
  text: string[];
}

export interface Cluster {
  cluster_entities: ClusterEntity[];
  count: number;
  user_count: number;
  frequency: number;
  label: string;
}

export interface ClusterEntity {
  blacklist: boolean;
  mentioned: boolean;
  user_count: number;
  over_used: boolean;
  high_density: boolean;
  topic_user_score: number;
  title_frequency: number;
  long_tail: boolean;
  net_score: number;
  count: number;
  entity: string;
  frequency: number;
  header_count: number;
  header_frequency: number;
  item_count: number;
  plural_lower: string;
  pos: string;
  sing_lower: string;
  title_count: number;
  type: string;
}

interface CancellablePromise<T> extends Promise<T> {
  cancel: () => void;
}

export const getUrlBatch = (
  urls: string[],
  cancelToken?: CancelToken
): CancellablePromise<UrlBatchResult> => {
  const promise = axiosService.post(
    "/processURLs",
    {
      urls: urls,
      timeout: 20,
      include_full_text: true,
    },
    {
      cancelToken: cancelToken,
    }
  ) as CancellablePromise<UrlBatchResult>;

  promise.cancel = () => {
    cancelToken?.cancel("Query was cancelled by React Query");
  };

  return promise;
};

type QueryFnType = typeof getUrlBatch;

type UseProcessUrlBatchOptions = {
  urls: string[];
  config?: QueryConfig<QueryFnType>;
  shouldSetSerpProcessed?: boolean;
  document: FraseDocument;
};

export const useUrlBatch = ({
  urls = [],
  config,
  shouldSetSerpProcessed = false,
  document: fraseDocument,
}: UseProcessUrlBatchOptions) => {
  const { setSerp, serp } = useSerpStore();
  const { topics: topicsStore, setTopics } = useTopicsStore();

  const cancelTokenSource = CancelToken.source();

  const serpKey = `${fraseDocument.query}:${fraseDocument.metadata.code}:${fraseDocument.metadata.lang_code}`;
  let processedUrls = [...urls];
  let customUrls = [] as string[];
  const blacklist = fraseDocument.metadata?.blacklist || {};
  const { score, selectedType, selectedStatus } = topicsStore[
    fraseDocument.id
  ] || {
    score: 0,
    selectedType: "longTail",
    selectedStatus: "all",
  };

  if (fraseDocument.metadata.custom_imports) {
    const articles = fraseDocument.metadata.custom_imports[serpKey];

    if (articles) {
      articles.forEach((article) => {
        if (article.hasOwnProperty("url")) {
          customUrls.push(article.url);
        }
      });
    }
  }

  processedUrls = [...customUrls, ...processedUrls];

  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["processUrlBatch", fraseDocument.query, processedUrls],
    queryFn: () => {
      const source = CancelToken.source();

      const promise = getUrlBatch(processedUrls, source.token);

      promise.cancel = () => {
        source.cancel("Query was cancelled by React Query");
      };

      return promise;
    },
    onSuccess: (data) => {
      const { items, cluster_info } = data;
      const serpData = serp[fraseDocument.id] || {};
      let searchResults = [...(serpData.results || [])] as object[];
      let importedResults = [] as object[];

      items &&
        items.forEach((item) => {
          const index = searchResults.findIndex(
            (result) => result.url === item.url
          );
          if (index === -1) {
            importedResults.push({
              title: item.title,
              url: item.url,
              description: item.description,
              isValid: true,
              isCustomImport: true,
              index: 0,
              dateCreated: "",
            });
          }
        });

      searchResults = [...importedResults, ...searchResults];

      searchResults = searchResults.filter((result) => {
        return !Object.keys(blacklist).includes(result.url);
      });

      const articles = validateArticles(items, searchResults);
      const { wordCount, headerCount, linkCount, imageCount } =
        getArticleCounts(articles);
      const headings = getHeadings(articles);
      const serpQuestions = getQuestions(articles);
      const { domain_map, topics } = processBrief(
        items,
        cluster_info,
        fraseDocument.query,
        fraseDocument.metadata?.lang_code
      );
      let scoredArticles = scoreSERP(topics, articles, fraseDocument);
      const scoredTopicsWithEditor = scoreTopics(
        scoredArticles,
        topics,
        cluster_info,
        fraseDocument,
        selectedType,
        [],
        [],
        ""
      );

      const processedTopics = filterTopics(
        scoredTopicsWithEditor.topics,
        selectedType || "longTail",
        selectedStatus || "all",
        fraseDocument
      );

      const processedClusters = filterClusters(
        scoredTopicsWithEditor.clusters,
        selectedStatus,
        fraseDocument
      );
      setTopics(fraseDocument.id, {
        ...topicsStore[fraseDocument.id],
        topics: processedTopics,
        clusters: processedClusters,
        score: scoredTopicsWithEditor.score,
        avgScore: scoredTopicsWithEditor.avg_score,
        topScore: scoredTopicsWithEditor.top_score,
        selectedType: selectedType,
        selectedStatus: selectedStatus,
      });

      setSerp(fraseDocument.id, {
        ...serpData,
        results: searchResults,
        articles: scoredArticles || [],
        clusters: cluster_info,
        headings: headings,
        topics: topics,
        domainMap: domain_map,
        questions: {
          ...serpData.questions,
          serp: serpQuestions,
        },
        averageWordCount: wordCount,
        averageHeaderCount: headerCount,
        averageImageCount: imageCount,
        averageLinkCount: linkCount,
        urls: processedUrls,
        serpLoaded: shouldSetSerpProcessed,
      });
    },
  });
};
