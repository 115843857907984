import cn from "clsx";
import * as React from "react";

import { Link } from "@/components/Elements/Link";
import { Spinner } from "@/components/Elements/Spinner";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/Elements/Tooltip";
import { ShortcutTag } from "../Tag/ShortcutTag";

function ArrowIcon(props) {
  return (
    <svg viewBox="0 0 20 20" fill="none" aria-hidden="true" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m11.5 6.5 3 3.5m0 0-3 3.5m3-3.5h-9"
      />
    </svg>
  );
}

const variantStyles = {
  primary:
    "rounded-md bg-emerald-600 py-1 px-3 text-white hover:bg-emerald-700 dark:bg-emerald-400/10 dark:text-emerald-400 dark:ring-1 dark:ring-inset dark:ring-emerald-400/20 dark:hover:bg-emerald-400/10 dark:hover:text-emerald-300 dark:hover:ring-emerald-300 focus:outline-none",
  primaryBlur:
    "rounded-md text-white backdrop-blur-lg bg-emerald-600 dark:bg-emerald-900/50 shadow-sm dark:shadow-lg ring-1 ring-emerald-900 dark:ring-1 dark:ring-emerald-50 dark:ring-opacity-10 ring-opacity-5 py-1 px-3 ring-1 ring-inset ring-emerald-900/10 hover:bg-emerald-700 dark:text-emerald-400 dark:ring-white/10 dark:hover:bg-emerald-900/20 focus:outline-none",
  destructiveBlur:
    "rounded-md text-white backdrop-blur-lg bg-red-500 dark:bg-red-900/50 shadow-sm dark:shadow-lg ring-1 ring-red-900 dark:ring-1 dark:ring-red-50 dark:ring-opacity-10 ring-opacity-5 py-1 px-3 ring-1 ring-inset ring-red-900/10 hover:bg-red-700 dark:text-red-400 dark:ring-white/10 dark:hover:bg-red-900/20 focus:outline-none",
  secondary:
    "rounded-md bg-zinc-100 py-1 px-3 text-zinc-900 hover:bg-zinc-200 dark:bg-zinc-800/40 dark:text-zinc-400 dark:ring-1 dark:ring-inset dark:ring-zinc-800 dark:hover:bg-zinc-800 dark:hover:text-zinc-300 focus:outline-none",
  filled:
    "rounded-md bg-zinc-900 py-1 px-3 text-white hover:bg-zinc-700 dark:bg-emerald-500 dark:text-white dark:hover:bg-emerald-400 focus:outline-none",
  outline:
    "rounded-md py-1 px-3 text-zinc-600 ring-1 ring-inset ring-zinc-900/10 hover:bg-zinc-900/2.5 hover:text-zinc-900 dark:text-zinc-400 dark:ring-white/10 dark:hover:bg-white/5 dark:hover:text-white focus:outline-none",
  outlineBlur:
    "rounded-md backdrop-blur-lg bg-white/90 dark:bg-zinc-900/50 shadow-sm dark:shadow-lg ring-1 ring-zinc-900 dark:ring-1 dark:ring-zinc-50 dark:ring-opacity-10 ring-opacity-5 py-1 px-3 text-zinc-600 ring-1 ring-inset ring-zinc-900/10 hover:bg-zinc-900/2.5 hover:text-zinc-900 dark:text-white dark:ring-white/10 dark:hover:bg-white/5 dark:hover:text-white focus:outline-none",
  aiBlur:
    "rounded-md backdrop-blur-lg bg-emerald-200/30 dark:bg-emerald-800/30 shadow-sm dark:shadow-lg ring-1 ring-emerald-600/20 dark:ring-1 dark:ring-emerald-300/20 py-1 px-3 text-emerald-700 dark:text-emerald-200 ring-1 ring-inset hover:bg-emerald-200/40 hover:text-emerald-800 dark:text-emerald-500 dark:ring-emerald-900 dark:hover:bg-emerald-800/40 dark:hover:text-emerald-200 focus:outline-none",
  outlineTab:
    "rounded-md py-0.5 px-2 text-zinc-600 ring-1 ring-inset ring-zinc-900/10 hover:bg-zinc-900/2.5 hover:text-zinc-900 dark:text-zinc-400 dark:ring-white/10 dark:hover:bg-white/5 dark:hover:text-white focus:outline-none",
  text: "rounded-md text-zinc-500 hover:text-zinc-600 dark:text-emerald-400 dark:hover:text-emerald-500 focus:outline-none focus:ring-transparent focus:outline-none py-1 px-3 text-zinc-600 hover:bg-zinc-100 hover:text-zinc-900 dark:text-zinc-400 dark:ring-white/10 dark:hover:bg-white/5 dark:hover:text-white focus:outline-none",
  textNode:
    "rounded-md text-zinc-500 hover:text-zinc-600 dark:text-emerald-400 dark:hover:text-emerald-500 focus:outline-none focus:ring-transparent focus:outline-none py-1 px-0 hover:text-zinc-900 dark:text-zinc-400 dark:ring-white/10 dark:hover:text-white focus:outline-none focus:ring-transparent",
  textTab:
    "rounded-md text-zinc-500 hover:text-zinc-600 dark:text-emerald-400 dark:hover:text-emerald-500 focus:outline-none focus:ring-transparent focus:outline-none py-0.5 px-2 text-zinc-600 hover:bg-zinc-100 hover:text-zinc-900 dark:text-zinc-400 dark:ring-white/10 dark:hover:bg-white/5 dark:hover:text-white focus:outline-none focus:ring-transparent",
  icon: "rounded-md hover:bg-zinc-900/5 dark:hover:bg-white/5 focus:outline-none text-zinc-800 dark:text-zinc-200",
  buttonIcon:
    "rounded-md inline-block h-7 w-7 min-w-7 min-h-7 align-middle items-center p-1 bg-transparent hover:bg-zinc-100 text-zinc-400 dark:text-zinc-600 hover:stroke-zinc-900 hover:text-zinc-900 hover:opacity-100 hover:text-zinc-900 dark:hover:bg-zinc-700 dark:hover:stroke-white dark:hover:text-white focus:outline-none z-10",
  tagIcon:
    "rounded-md inline-block h-7 w-7 min-w-8 min-h-8 align-middle items-center hover:bg-zinc-200/50 hover:stroke-zinc-900 text-zinc-500 hover:text-zinc-900 dark:text-white",
  buttonNodeIcon:
    "rounded-md inline-block h-7 w-7 min-w-8 min-h-8 align-middle items-center p-1 bg-transparent hover:bg-zinc-100 dark:hover:bg-zinc-700 text-current focus:outline-none z-10",
  floatingIcon: "opacity-40 hover:opacity-100 focus:outline-none",
  node: "px-2",
  buttonNode:
    "rounded-full hover:bg-opacity-50 dark:hover:bg-white/5 dark:text-white",
  dropdown: "px-2",
  none: "",
};

const sizes = {
  "4xs": "text-4xs",
  "2xs": "text-2xs",
  xs: "text-xs",
  sm: "text-sm",
  md: "text-md",
  lg: "text-lg",
};

type IconProps =
  | { startIcon: React.ReactElement; endIcon: React.ReactElement }
  | { startIcon: React.ReactElement; endIcon?: never }
  | { endIcon: React.ReactElement; startIcon?: never }
  | { endIcon?: undefined; startIcon?: undefined };

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: keyof typeof variantStyles;
  size?: keyof typeof sizes;
  arrow?: "left" | "right";
  isLoading?: boolean;
  tooltipContent?: string;
  tooltipModifierKey?: string;
  startIcon?: React.ReactElement;
  endIcon?: React.ReactElement;
  buttonIcon?: React.ReactElement;
  textClassName?: string;
  tooltipDirection?: "top" | "right" | "bottom" | "left";
  tooltipDelay?: number;
  disabled?: boolean;
} & IconProps;

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      type = "button",
      className = "",
      textClassName,
      variant = "primary",
      size = "sm",
      isLoading = false,
      startIcon,
      endIcon,
      buttonIcon,
      arrow,
      tooltipContent,
      tooltipModifierKey,
      tooltipDirection = "bottom",
      tooltipDelay = 500,
      disabled = false,
      ...props
    },
    ref
  ) => {
    let Component = props.to ? Link : "button";

    className = cn(
      "inline-flex gap-0.5 justify-center items-center overflow-hidden font-medium transition",
      "focus:outline-none focus:ring-none",
      variantStyles[variant],
      sizes[size],
      className,
      disabled && "opacity-50 cursor-not-allowed",
      isLoading && "pointer-events-none"
    );

    let arrowIcon = (
      <ArrowIcon
        className={cn(
          "mt-0.5 h-5 w-5",
          variant === "text" && "relative top-px",
          arrow === "left" && "-ml-1 rotate-180",
          arrow === "right" && "-mr-1"
        )}
      />
    );

    startIcon =
      startIcon &&
      React.cloneElement(startIcon, {
        className: cn(
          size === "2xs" && "h-2.5 w-2.5",
          size === "xs" && "h-3 w-3",
          size === "sm" && "h-3.5 w-3.5",
          size === "md" && "h-4 w-4",
          size === "lg" && "h-5 w-5",
          variant === "primaryBlur" &&
            "text-white stroke-white dark:text-zinc-900 dark:stroke-emerald-400",
          variant === "destructiveBlur" &&
            "text-white stroke-white dark:text-zinc-900 dark:stroke-red-400",
          variant === "buttonIcon" &&
            "text-white stroke-white dark:text-zinc-900",
          variant === "outlineBlur" &&
            "text-zinc-900 stroke-zinc-600 dark:text-white dark:stroke-white",
          props.children && "mr-1",
          startIcon.props.className
        ),
      });
    endIcon =
      endIcon &&
      React.cloneElement(endIcon, {
        className: cn(
          size === "2xs" && "h-2.5 w-2.5",
          size === "xs" && "h-3 w-3",
          size === "sm" && "h-3.5 w-3.5",
          size === "md" && "h-4 w-4",
          size === "lg" && "h-4.5 w-4.5",
          variant === "primaryBlur" &&
            "text-white stroke-white dark:text-zinc-900 dark:stroke-emerald-400",
          variant === "destructiveBlur" &&
            "text-white stroke-white dark:text-zinc-900 dark:stroke-red-400",
          variant === "buttonIcon" &&
            "text-white stroke-white dark:text-zinc-900",
          variant === "outlineBlur" &&
            "text-zinc-900 stroke-zinc-600 dark:text-white dark:stroke-white",
          props.children && "ml-1"
        ),
      });
    buttonIcon =
      buttonIcon &&
      React.cloneElement(buttonIcon, {
        className: cn(
          size === "2xs" && "h-2.5 w-2.5",
          size === "xs" && "h-3 w-3",
          size === "sm" && "h-3.5 w-3.5",
          size === "md" && "h-4 w-4",
          size === "lg" && "h-4 w-4.5",
          variant === "primaryBlur" &&
            "text-white stroke-white dark:text-zinc-900 dark:stroke-emerald-400",
          variant === "destructiveBlur" &&
            "text-white stroke-white dark:text-zinc-900 dark:stroke-red-400"
        ),
      });

    if (tooltipContent) {
      return (
        <TooltipProvider delayDuration={500} skipDelayDuration={0}>
          <Tooltip delay={tooltipDelay}>
            <TooltipTrigger
              asChild
              autoFocus={false}
              onFocus={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <Component
                ref={ref}
                type={type}
                className={className}
                disabled={disabled}
                {...props}
              >
                {isLoading && (
                  <Spinner size="sm" className="text-transparent mr-2" />
                )}
                {!isLoading && startIcon}
                {arrow === "left" && arrowIcon}
                {buttonIcon}
                <span className={textClassName}>{props.children}</span>
                {arrow === "right" && arrowIcon}
                {!isLoading && endIcon}
              </Component>
            </TooltipTrigger>
            <TooltipContent side={tooltipDirection}>
              {tooltipContent}
              {tooltipModifierKey && (
                <ShortcutTag>{tooltipModifierKey}</ShortcutTag>
              )}
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      );
    }

    return (
      <Component
        ref={ref}
        type={type}
        className={className}
        disabled={disabled}
        {...props}
      >
        {isLoading && (
          <Spinner size="sm" className="text-transparent/40 mr-2" />
        )}
        {!isLoading && startIcon}
        {arrow === "left" && arrowIcon}
        {buttonIcon}
        <span className={textClassName}>{props.children}</span>
        {arrow === "right" && arrowIcon}
        {!isLoading && endIcon}
      </Component>
    );
  }
);
