import { ExtendedTextNode } from "@/components/AdvancedEditor/plugins/ExtendedTextNodePlugin/ExtendedTextNodePlugin";
import { LogoSpinner } from "@/components/Elements/Spinner/LogoSpinner";
import { DocumentTab } from "@/components/Layout/DocumentHeader";
import { useUser } from "@/features/auth";
import { useDocumentStore } from "@/stores/document";
import { useEditorStore } from "@/stores/editor";
import { DocumentIcon } from "@heroicons/react/24/solid";
import { $generateNodesFromDOM } from "@lexical/html";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";
import * as Sentry from "@sentry/react";
import dayjs from "dayjs";
import {
  $createParagraphNode,
  $getRoot,
  $insertNodes,
  TextNode,
} from "lexical";
import { useEffect, useMemo, useState } from "react";
import PlaygroundNodes from "../../../components/AdvancedEditor/nodes/PlaygroundNodes";
import PlaygroundEditorTheme from "../../../components/AdvancedEditor/themes/PlaygroundEditorTheme";
import {
  Avatar,
  AvatarFallback,
  Button,
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogFooter,
} from "../../../components/Elements";
import { useNotificationStore } from "../../../stores/notifications";
import { cn, usernameToColor } from "../../../utils/style";
import { useFullVersionForID } from "../api/getDocumentVersionFullText";
import {
  DocumentVersion,
  useGetDocumentVersionsNoText,
} from "../api/getDocumentVersionsNoText";
import { useUpdateDocument } from "../api/updateDocument";

const PrepoulateContentPreviewPlugin = ({ html }: { html: string }) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    editor.update(() => {
      if (html) {
        const parser = new DOMParser();
        const dom = parser.parseFromString(html || "", "text/html");

        let nodes = $generateNodesFromDOM(editor, dom);
        const root = $getRoot();
        root.select();
        root.clear();

        if (nodes.length === 0) {
          nodes.push($createParagraphNode());
        }

        $insertNodes(nodes);
      } else {
        const root = $getRoot();
        root.select();
        root.clear();
      }
    });
  }, [html, editor]);

  return null;
};

function ContentPreviewEditor({
  html,
  title,
  version,
  loading, // Add loading prop
  renderTabsDesktop,
}: {
  html: string;
  title?: string;
  version?: DocumentVersion;
  loading?: boolean; // Make loading optional
  renderTabsDesktop: React.ReactNode;
}) {
  return (
    <div
      className={cn("space-y-4 flex flex-col flex-1 pt-4 px-4 overflow-hidden")}
    >
      <div className="flex justify-between">
        <div className="flex flex-col space-y-0.5">
          <p className="w-full text-xl font-semibold text-zinc-800 dark:text-zinc-200">
            Restore version {version?.version_number || "current"}{" "}
          </p>
        </div>
      </div>

      <div className="flex flex-col space-y-2 flex-1 overflow-hidden">
        <div className="border-zinc-100 border dark:border-zinc-700 rounded-md flex-1 overflow-y-scroll">
          {loading ? (
            <div className="relative flex items-center justify-center w-full h-full">
              <LogoSpinner variant="md" loadingText="Loading version..." />
            </div>
          ) : (
            <>
              <div className="flex flex-row items-center space-x-2 pt-2 px-3 border-b">
                {renderTabsDesktop}
              </div>
              <LexicalComposer
                initialConfig={{
                  editable: false,
                  namespace: "Preview",
                  onError: (error: Error) => {
                    Sentry.captureException(error);
                  },
                  nodes: [
                    ...PlaygroundNodes,
                    ExtendedTextNode,
                    {
                      replace: TextNode,
                      with: (node: TextNode) =>
                        new ExtendedTextNode(node.__text),
                    },
                  ],
                  theme: PlaygroundEditorTheme,
                }}
              >
                <p className="h-[40px] w-full max-w-[750px] leading-[1.2] mx-auto outline-none border-none whitespace-pre-wrap break-words ring-transparent focus:outline-none  focus:border-none focus:ring-transparent text-[2em] font-bold  placeholder:text-zinc-400 dark:placeholder:text-zinc-600  bg-transparent text-zinc-900 dark:text-zinc-50 resize-none overflow-hidden mt-12 mb-8 px-8">
                  {title}
                </p>
                <PlainTextPlugin
                  contentEditable={
                    <ContentEditable className="h-full w-full max-w-[750px] flex-1 mx-auto min-w-0 mb-40 px-8" />
                  }
                  placeholder={null}
                  ErrorBoundary={LexicalErrorBoundary}
                />
                <PrepoulateContentPreviewPlugin html={html} />
              </LexicalComposer>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export const VersionControlDialog = ({
  open,
  setOpen,
  trigger,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  trigger?: React.ReactNode;
}) => {
  const { document: fraseDocument, setDocument } = useDocumentStore();
  const { editor: editorStore, setEditor } = useEditorStore();
  const updateDocumentMutation = useUpdateDocument({
    notifyOnSuccess: false,
  });
  const { data: user } = useUser();
  const { activeTabIndex } = editorStore;
  const [editor] = useLexicalComposerContext();
  const [isInsertingToEditor, setIsInsertingToEditor] = useState(false);
  const [previewTitle, setPreviewTitle] = useState("");
  const [previewHtml, setPreviewHtml] = useState("");
  const [selectedVersion, setSelectedVersion] = useState<DocumentVersion>({
    created_by: 0,
    created_by_name: "",
    date_created: 0,
    doc_hash: "",
    id: 0,
    text: "",
    version_number: 0,
    word_count: 0,
  });
  const [isLoadingVersion, setIsLoadingVersion] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const { data: fullVersionData } = useFullVersionForID({
    versionId: selectedVersion?.id || 0,
    docHash: selectedVersion?.doc_hash || "",
    config: { enabled: !!selectedVersion?.id },
  });

  const { addNotification } = useNotificationStore();

  const getDocumentVersions = useGetDocumentVersionsNoText({
    docHash: fraseDocument.hash,
    config: {
      enabled: false,
    },
  });
  const { data: versions, isLoading: isLoadingVersions } = getDocumentVersions;

  useEffect(() => {
    if (open) {
      getDocumentVersions.refetch();
    }
  }, [open]);

  const updatePreviewContent = (
    documentVersion: DocumentVersion,
    tabIndex: number = activeTab
  ) => {
    let documentContent = {
      html: "",
      title: "",
      name: "Tab 1", // Assuming "Tab 1" is the default or initial tab name
    };

    if (Array.isArray(documentVersion.text)) {
      documentContent = documentVersion.text[tabIndex] || documentContent;
    }

    setPreviewHtml(documentContent.html);
    setPreviewTitle(
      documentContent.title ||
        `Version ${documentVersion.version_number || "Current Version"}`
    );
    setSelectedVersion(documentVersion);
  };

  useEffect(() => {
    if (fraseDocument && fraseDocument.text) {
      updatePreviewContent(fraseDocument);
    } else if (!isLoadingVersions && versions && versions.length > 0) {
      const latestVersion = versions[versions.length - 1];
      updatePreviewContent(latestVersion);
    }
  }, [fraseDocument, isLoadingVersions, versions, activeTabIndex]);

  const handleVersionSelection = (version) => {
    setIsLoadingVersion(true); // Start loading
    if (version.id === fraseDocument.id) {
      updatePreviewContent(fraseDocument);
      setIsLoadingVersion(false); // End loading
    } else {
      setSelectedVersion(version);
    }
  };

  useEffect(() => {
    if (fullVersionData) {
      updatePreviewContent(fullVersionData);
      setIsLoadingVersion(false); // End loading when data is fetched
    }
  }, [fullVersionData]);

  const handleInsertToEditor = async () => {
    setIsInsertingToEditor(true);

    // Update the document with the selected version.
    updateDocumentMutation
      .mutateAsync({
        ...fraseDocument,
        text: selectedVersion.text,
      })
      .then((updatedDocument) => {
        // Replace the current editor preview with the version preview text for the active tab.
        editor.update(() => {
          const dom = new DOMParser().parseFromString(previewHtml, "text/html");
          let nodes = $generateNodesFromDOM(editor, dom);
          const root = $getRoot();
          root.select();
          root.clear();

          if (nodes.length === 0) {
            nodes.push($createParagraphNode());
          }

          $insertNodes(nodes);
          root.selectStart();
        });

        // Set activeTabIndex based on activeTab.
        setEditor((editorState) => {
          editorState.activeTabIndex = activeTab;
        });

        setIsInsertingToEditor(false);
        addNotification({
          type: "success",
          title: `Restored document version`,
          message: (
            <span className="flex items-center">
              <DocumentIcon className="w-3.5 h-3.5 fill-zinc-500 text-zinc-500" />
              <span className="ml-2 text-xs">{previewTitle || "Untitled"}</span>
            </span>
          ),
        });
        setOpen(false);
      });
  };

  const renderTabsDesktop = useMemo(() => {
    if (Array.isArray(selectedVersion?.text)) {
      return selectedVersion.text.map((tab, index) => {
        const isActive = index === activeTab;
        return (
          <DocumentTab
            readOnly={true}
            key={index}
            {...tab}
            index={index}
            isLast={selectedVersion?.text?.length - 1 === 0}
            isActive={isActive}
            onClick={() => {
              setActiveTab(index);
              updatePreviewContent(selectedVersion, index);
            }}
            handleRenameTab={{}}
            handleDeleteTab={() => {}}
          />
        );
      });
    }
    return null;
  }, [selectedVersion?.text, activeTab]);

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        setOpen(open);
      }}
    >
      <DialogContent
        className="h-[90vh] max-w-[1250px] mx-12"
        variant="templates"
        containerClassName="p-0"
        style={{
          padding: "0",
        }}
      >
        <div className="flex h-full w-full">
          <div className="flex-1 space-y-4 flex flex-col">
            <ContentPreviewEditor
              html={previewHtml}
              title={previewTitle}
              version={selectedVersion}
              loading={isLoadingVersion}
              renderTabsDesktop={renderTabsDesktop}
            />

            <DialogFooter className="px-4 pb-4 text-center">
              <Button
                variant="outlineBlur"
                onClick={() => {
                  setOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="primaryBlur"
                disabled={isInsertingToEditor}
                onClick={() => {
                  handleInsertToEditor();
                }}
                isLoading={isInsertingToEditor}
              >
                Restore version
              </Button>
            </DialogFooter>
          </div>

          <div className="flex flex-col w-80 rounded-br-md rounded-tr-md bg-zinc-50 dark:bg-zinc-800 border-l dark:border-l-zinc-700">
            <div className="flex items-center justify-end p-4">
              <DialogCloseButton
                close={() => {
                  setOpen(false);
                }}
                className=""
              />
            </div>
            <div className="h-full overflow-auto pb-4">
              <div className="flex flex-col space-y-0.5">
                <div className="px-4">
                  <Button
                    key={fraseDocument.id}
                    variant="text"
                    size="sm"
                    className={cn(
                      "w-full text-left hover:bg-zinc-200/50 ",
                      // Assuming you have a way to determine if this is the selected version
                      selectedVersion?.id === fraseDocument.id &&
                        "bg-zinc-200/50 dark:bg-zinc-700/50"
                    )}
                    textClassName={cn(
                      "w-full text-zinc-500 dark:text-zinc-500 hover:text-zinc-800 dark:hover:text-white text-sm",
                      selectedVersion?.id === fraseDocument.id &&
                        "text-zinc-800 dark:text-white"
                    )}
                    onClick={() => handleVersionSelection(fraseDocument)} // Adjust this function to handle the current document
                    autoFocus={
                      selectedVersion?.id === fraseDocument.id ? true : false
                    }
                  >
                    <div className="flex flex-col justify-between space-y-1 py-2">
                      <div className="flex flex-row space-x-1 items-center justify-between w-full">
                        <div className="flex items-center space-x-1">
                          <span className="text-zinc-800 dark:text-zinc-200">
                            {dayjs(fraseDocument.lastEdited).format(
                              "MMMM D, YYYY"
                            )}
                          </span>
                          <span className="text-zinc-500 text-xs">
                            {dayjs(fraseDocument.lastEdited).format("h:mmA")}
                          </span>
                        </div>
                        <span className="text-zinc-600 text-2xs bg-zinc-200 dark:bg-zinc-600 dark:text-white rounded-md px-2 w-fit">
                          Current
                        </span>
                      </div>

                      <div className=" flex items-center space-x-2">
                        <Avatar className="w-4 h-4">
                          <AvatarFallback
                            className={cn(
                              "text-zinc-50 text-4xs",
                              usernameToColor(user?.fullName || "")
                            )}
                          >
                            {user?.fullName
                              ?.split(" ")
                              .map((name) => name[0])
                              .slice(0, 2)
                              .join("") ?? ""}
                          </AvatarFallback>
                        </Avatar>
                        <span className="text-zinc-500 text-xs">
                          {user?.fullName || ""}
                        </span>
                      </div>
                    </div>
                  </Button>
                </div>

                <div className="flex flex-col space-y-0.5 px-4 overflow-auto">
                  {versions?.map((version) => (
                    <Button
                      key={version.id}
                      variant="text"
                      size="sm"
                      className={cn(
                        "w-full text-left hover:bg-zinc-200/50",
                        selectedVersion?.id === version.id &&
                          "bg-zinc-200/50 dark:bg-zinc-700/50"
                      )}
                      textClassName={cn(
                        "w-full text-zinc-500 dark:text-zinc-500 hover:text-zinc-800 dark:hover:text-white text-sm",
                        selectedVersion?.id === version.id &&
                          "text-zinc-800 dark:text-white"
                      )}
                      onClick={() => handleVersionSelection(version)}
                      autoFocus={
                        selectedVersion?.id === version.id ? true : false
                      }
                    >
                      <div className="flex flex-col justify-between space-y-0.5 py-1">
                        <div className="flex flex-row space-x-1 items-center">
                          <span className="text-zinc-800 dark:text-zinc-200">
                            {dayjs(version.date_created).format("MMMM D, YYYY")}{" "}
                          </span>
                          <span className="text-zinc-500 text-xs">
                            {dayjs(version.date_created).format("h:mmA")}
                          </span>{" "}
                        </div>

                        <div className="flex items-center space-x-2">
                          <Avatar className="w-4 h-4">
                            <AvatarFallback
                              className={cn(
                                "text-zinc-50 text-4xs",
                                usernameToColor(
                                  version.created_by_name || "Guest"
                                )
                              )}
                            >
                              {(version.created_by_name || "Guest")
                                .split(" ")
                                .map((name) => name[0])
                                .slice(0, 2)
                                .join("")}
                            </AvatarFallback>
                          </Avatar>
                          <span className="text-zinc-500 text-xs">
                            {version.created_by_name || "Guest"}
                          </span>
                        </div>
                      </div>
                    </Button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
