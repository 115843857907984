import { QuestionAsset } from "@/components/Elements/Asset/QuestionAsset";
import { LogoSpinner } from "@/components/Elements/Spinner/LogoSpinner";
import { useBingSearchResults } from "@/features/documents/api/getBingSearchResults";
import { useRedditSearchResults } from "@/features/documents/api/getRedditSearchResults";
import {
  getQuoraQuery,
  getRedditQuery,
  processQuoraResults,
  processRedditResults,
} from "@/features/documents/utils/questions";
import { processBingSearchResults } from "@/features/documents/utils/research";
import { useDocumentStore } from "@/stores/document";
import { useSerpStore } from "@/stores/serp";
import { useState } from "react";

const panels = [
  {
    value: "serp",
    title: "SERP",
  },
  {
    value: "peopleAlsoAsk",
    title: "People Also Ask",
  },
  {
    value: "quora",
    title: "Quora",
  },
  {
    value: "reddit",
    title: "Reddit",
  },
];

function Serp() {
  const { document } = useDocumentStore();
  const { serp } = useSerpStore();
  const docId = document.id;
  const currentSerp = serp[docId] || {
    questions: {
      serp: [],
      people_also_ask: [],
      quora: [],
      reddit: [],
    },
  };
  const serpQuestions = currentSerp.questions.serp;

  if (serpQuestions.length === 0) {
    return <EmptyContent />;
  }

  return (
    <ul className="grid">
      {serpQuestions.map((item, index) => {
        return (
          <li key={index}>
            <QuestionAsset
              key={index}
              headerText={item.header}
              headerType={item.header_tag}
              html={item.html}
              assetText={item.text}
            />
          </li>
        );
      })}
    </ul>
  );
}

export const PeopleAlsoAsk = ({ questions }: { questions: string[] }) => {
  if (!questions || questions.length === 0) {
    return;
  }

  return (
    <ul className="grid py-2 mb-2">
      <div className="flex flex-row items-center">
        <span className="text-xs text-zinc-500">People Also Ask</span>
      </div>
      {questions &&
        questions
          .map((item) => item.replace(" ?", "?")) // replace instances of ' ?' with '?'.
          .slice(0, 5)
          .map((item, index) => {
            return (
              <li key={index}>
                <QuestionAsset
                  key={index}
                  headerText={item}
                  assetText={[]}
                  collapseDisabled
                />
              </li>
            );
          })}
    </ul>
  );
};

function Quora() {
  const { document } = useDocumentStore();
  const queries = getQuoraQuery(
    document.query,
    document.metadata.lang_code,
    document.metadata.code
  );
  const bingSearchQuery = useBingSearchResults({
    queries,
  });

  if (bingSearchQuery.isLoading) {
    return <LoadingContent />;
  }

  const bingSearchResults = processBingSearchResults(
    bingSearchQuery.data || {}
  );
  const quoraResults = processQuoraResults(bingSearchResults);

  if (quoraResults.length === 0) {
    return <EmptyContent />;
  }

  return (
    <ul className="grid mt-2">
      {quoraResults.map((item, index) => {
        return (
          <li key={index}>
            <QuestionAsset
              key={index}
              headerText={item.question}
              assetText={[]}
            />
          </li>
        );
      })}
    </ul>
  );
}

function Reddit() {
  const { document } = useDocumentStore();
  const queries = getRedditQuery(document.query, document.metadata.lang_code);
  const redditSearchQuery = useRedditSearchResults({
    queries,
  });

  if (redditSearchQuery.isLoading) {
    return <LoadingContent />;
  }

  const redditSearchResults = processRedditResults(
    redditSearchQuery.data || {},
    document.query
  );

  if (redditSearchResults.length === 0) {
    return <EmptyContent />;
  }

  return (
    <ul className="grid mt-2">
      {redditSearchResults.map((item, index) => {
        return (
          <li key={index}>
            <QuestionAsset
              key={index}
              headerText={item.question}
              assetText={[]}
            />
          </li>
        );
      })}
    </ul>
  );
}

function LoadingContent() {
  return (
    <div className="flex items-center justify-center w-full h-full">
      <LogoSpinner variant="md" />
    </div>
  );
}

function EmptyContent() {
  return (
    <div className="flex items-center justify-center w-full h-full">
      <p className="text-sm text-gray-500">No results found</p>
    </div>
  );
}

export default function Questions() {
  const [activePanel, setActivePanel] = useState(panels[0].value);

  return (
    <ul className="px-4 grid">
      <Serp />
    </ul>
  );
}
