import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Command,
  CommandGroup,
  CommandItem,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/Elements";
import { ModeToggle } from "@/components/Layout/ModeToggle";
import { ShareFeedbackButton } from "@/features/help/components/ShareFeedbackDialog";
import { useAuth } from "@/lib/auth";
import storage from "@/utils/storage";
import { cn, usernameToColor } from "@/utils/style";
import { Cog6ToothIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import {
  TbArrowBack,
  TbHelpCircleFilled,
  TbLogout,
  TbSelector,
} from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { SubmitTicketButton } from "../../../features/help/components/SubmitTicketDialog";
import { Skeleton } from "../Skeleton";

export function AvatarMenu({
  fullName = "",
  isLoading,
}: {
  fullName?: string;
  isLoading?: boolean;
}) {
  const navigate = useNavigate();
  const { user } = useAuth();

  // TODO: Remove after legacy user migration
  const isLegacyUser = user?.legacy || false;

  let userActions = [
    {
      name: "Sign out",
      href: "#",
      value: "signOut",
      icon: <TbLogout className="w-3.5 h-3.5 mr-2" />,
      onSelect: () => {
        storage.clearToken();
        window.location.assign(window.location.origin as unknown as string);
      },
    },
  ];

  let userNavigation = [
    /*{
    name: "Account",
    value: "account",
    href: "/app/account",
    icon: <UserCircleIcon className="w-3.5 h-3.5 mr-2" />,
  },*/
    {
      name: "Settings",
      value: "settings",
      href: "/app/settings",
      icon: <Cog6ToothIcon className="w-3.5 h-3.5 mr-2" />,
      onSelect: () => {
        navigate("/app/settings/account");
      },
    },
    {
      name: "Help Center",
      value: "helpCenter",
      href: "/app/billing",
      icon: <TbHelpCircleFilled className="w-3.5 h-3.5 mr-2" />,
      onSelect: () => {
        window.open("https://support.frase.io/", "_blank");
      },
    },
  ];

  if (isLegacyUser) {
    userActions = [
      {
        name: "Switch to Frase Legacy",
        value: "switchToLegacy",
        href: "#",
        icon: <TbArrowBack className="w-3.5 h-3.5 mr-2" />,
        onSelect: () => {
          window.open("https://app.frase.io/", "_blank");
        },
      },
      ...userActions,
    ];
  }

  return (
    <Popover>
      <PopoverTrigger
        asChild
        className="hover:bg-zinc-900/5 dark:hover:bg-white/5 cursor-pointer"
      >
        <li
          className={clsx(
            "flex items-center mx-3 py-1 text-zinc-500 hover:text-zinc-900 dark:text-white dark:hover:text-white hover:bg-zinc-900/5 dark:hover:bg-white/5 group appearance-none"
          )}
        >
          <Avatar className="w-4 h-4 ml-2">
            <AvatarImage src="" />
            <AvatarFallback
              className={cn(
                "text-white font-semibold text-4xs",
                usernameToColor(fullName)
              )}
            >
              {fullName
                .split(" ")
                .map((name) => name[0])
                .slice(0, 2)
                .join("")}
            </AvatarFallback>
          </Avatar>

          {isLoading ? (
            <Skeleton className="w-32 ml-2" />
          ) : (
            <h2 className="text-2xs font-medium text-zinc-900 dark:text-white truncate text-ellipsis w-full">
              <div className="flex justify-between items-center w-full">
                <div className="flex self-start items-center">
                  <span className="ml-2 text-xs text-zinc-800 dark:text-white truncate w-32 block group-hover:text-zinc-900 dark:group-hover:text-white">
                    {fullName}
                  </span>
                </div>
                <TbSelector
                  className="h-4 w-4 stroke-zinc-600 dark:stroke-white mr-[8.5px]"
                  aria-hidden="true"
                />
              </div>
            </h2>
          )}
        </li>
      </PopoverTrigger>
      <PopoverContent>
        <Command>
          <CommandGroup className="border-b dark:border-b-zinc-400 border-b-zinc-200 w-48 focus:outline-none outline-none">
            {userNavigation.map((option, index) => (
              <CommandItem key={index} onSelect={option.onSelect}>
                {option.icon}
                {option.name}
              </CommandItem>
            ))}
          </CommandGroup>
          <CommandGroup className="border-b dark:border-b-zinc-400 border-b-zinc-200 w-48 focus:outline-none outline-none">
            <ShareFeedbackButton />
            <SubmitTicketButton />
            <ModeToggle />
          </CommandGroup>
          <CommandGroup>
            {userActions.map((option, index) => (
              <CommandItem key={index} onSelect={option.onSelect}>
                {option.icon}
                {option.name}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
