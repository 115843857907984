import {
  Button,
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DropdownMenuItem,
} from "@/components/Elements";
import { DialogClose, DialogPortal } from "@radix-ui/react-dialog";
import React, { forwardRef, useState } from "react";

type ConfirmDeleteDialogButtonProps = {
  title: string;
  description: React.ReactNode;
  onConfirm: () => void | Promise<void>;
  confirmButtonLabel?: string;
  isLoading?: boolean;
  triggerButtonProps?: React.ComponentProps<typeof Button> & {
    asDropdownItem?: boolean;
    onOpenChange?: (isOpen: boolean) => void;
  };
  triggerButtonLabel?: string;
};

export const ConfirmDeleteDialogButton: React.FC<ConfirmDeleteDialogButtonProps> =
  forwardRef(
    (
      {
        title,
        description,
        triggerButtonLabel = "Delete",
        confirmButtonLabel = "Delete",
        onConfirm,
        isLoading = false,
        triggerButtonProps,
      },
      forwardedRef
    ) => {
      const [open, setOpen] = useState(false);

      const trigger = triggerButtonProps?.asDropdownItem ? (
        <DropdownMenuItem
          className="text-red-500"
          onSelect={(e) => {
            e.preventDefault();
            setOpen(true);
            e.stopPropagation();
          }}
          ref={forwardedRef}
        >
          {triggerButtonLabel}
        </DropdownMenuItem>
      ) : (
        <Button
          variant="destructiveBlur"
          className="text-red-600 dark:text-red-400 h-7 ml-2"
          textClassName="font-normal text-xs"
          {...triggerButtonProps}
        >
          {triggerButtonLabel}
        </Button>
      );

      return (
        <Dialog
          onOpenChange={
            triggerButtonProps?.onOpenChange
              ? triggerButtonProps.onOpenChange
              : (open) => {
                  setOpen(open);
                }
          }
          open={open}
        >
          <DialogTrigger asChild>{trigger}</DialogTrigger>
          <DialogPortal>
            <DialogContent>
              <DialogHeader className="pb-0 flex items-center justify-between">
                <DialogTitle>{title}</DialogTitle>
                <DialogCloseButton close={() => setOpen(false)} />
              </DialogHeader>
              <DialogDescription className="px-4">
                {description}
              </DialogDescription>
              <DialogFooter className="px-4 pb-4">
                <DialogClose asChild>
                  <Button variant="outlineBlur" onClick={() => setOpen(false)}>
                    Cancel
                  </Button>
                </DialogClose>
                <Button
                  isLoading={isLoading}
                  variant="destructiveBlur"
                  className="bg-red-600"
                  onClick={async () => {
                    await onConfirm();
                    setOpen(false);
                  }}
                >
                  {confirmButtonLabel}
                </Button>
              </DialogFooter>
            </DialogContent>
          </DialogPortal>
        </Dialog>
      );
    }
  );
