import { useActiveTab } from "@/components/AdvancedEditor/hooks/useActiveTab";
import { useEditorStore } from "@/stores/editor";
import { cn } from "@/utils/style";
import { TooltipPortal } from "@radix-ui/react-tooltip";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../Tooltip";

export function SolidCircleProgress({
  progress,
  className,
  stripColor,
  strokeWidth = 10,
  size = 100,
  textClassName,
  markerRadius = 6,
  averageProgress,
  visible = true,
}: {
  progress: number;
  className?: string;
  textClassName?: string;
  stripColor?: string;
  strokeWidth?: number;
  size?: number;
  markerRadius?: number;
  averageProgress?: number;
  visible?: boolean;
}) {
  const circleSize = size;
  const radius = (circleSize - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const progressOffset = ((100 - Math.floor(progress)) / 100) * circumference;
  const { setEditor } = useEditorStore();
  const { setActiveTab } = useActiveTab();

  const gradientId = "progress-gradient";
  const maskId = "progress-mask";

  const markerAngle = ((100 - averageProgress) / 100) * 360 - 90;
  const markerPosition = {
    x: circleSize / 2 + radius * Math.cos((markerAngle * Math.PI) / 180),
    y: circleSize / 2 + radius * Math.sin((markerAngle * Math.PI) / 180),
  };

  const progressColor = () => {
    if (progress >= 0 && progress <= 25) {
      return {
        start: "#f87171",
        end: "#f87171",
      };
    }

    if (progress > 25 && progress < 50) {
      return {
        start: "#fcd34d",
        end: "#fbbf24",
      };
    }

    if (progress >= 50) {
      return {
        start: "#10b981",
        end: "#10b981",
      };
    }
  };

  let colorCodes = progressColor();
  let start = colorCodes?.start;
  let end = colorCodes?.end;

  return (
    <div
      className={cn("relative", className)}
      onClick={() => {
        setEditor((editorState) => {
          editorState.optimizeViewVisible = true;
          editorState.highlightTopics = true;
          editorState.optimizeScrollToTop = true;
        });
        setActiveTab("optimize");
      }}
      id="topic-score-progress"
    >
      <svg
        className="w-full h-full transform -rotate-90"
        viewBox={`0 0 ${circleSize} ${circleSize}`}
      >
        <defs>
          <linearGradient
            id={gradientId}
            x1="0%"
            y1="0%"
            x2="100%"
            y2="0%"
            gradientUnits="userSpaceOnUse"
            className="transition-all duration-500"
          >
            <stop offset="0%" style={{ stopColor: start }} />
            <stop offset="100%" style={{ stopColor: end }} />
          </linearGradient>
          <mask id={maskId}>
            <circle
              className="transition-all duration-500 stroke-current text-white"
              strokeWidth={strokeWidth}
              strokeLinecap="round"
              fill="transparent"
              r={radius}
              cx={circleSize / 2}
              cy={circleSize / 2}
              strokeDasharray={circumference}
              strokeDashoffset={progressOffset}
            />
          </mask>
        </defs>
        <circle
          className="transition-all duration-500 stroke-current text-zinc-200 dark:text-zinc-600"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          fill="transparent"
          r={radius}
          cx={circleSize / 2}
          cy={circleSize / 2}
        />
        <circle
          className="transition-all duration-500"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          fill="transparent"
          r={radius}
          cx={circleSize / 2}
          cy={circleSize / 2}
          stroke={`url(#${gradientId})`}
          mask={`url(#${maskId})`}
        />
        {averageProgress && (
          <circle
            className="fill-current text-zinc-600"
            r={markerRadius}
            cx={markerPosition.x}
            cy={markerPosition.y}
          />
        )}
      </svg>
      {visible && (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <div className="absolute inset-0 flex items-center justify-center hover:cursor-pointer">
                <span
                  className={cn(
                    "text-sm font-bold text-zinc-600 dark:text-white hover:text-zinc-900",
                    textClassName
                  )}
                >
                  {Math.floor(progress)}%
                </span>
              </div>
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent side="bottom">Topic Score</TooltipContent>
            </TooltipPortal>
          </Tooltip>
        </TooltipProvider>
      )}
    </div>
  );
}
