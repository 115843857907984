import { useSubscription } from "@/features/auth/api/getSubscription";
import HelpCenterPanel from "@/features/help/components/HelpCenterDialog";
import { useWindowSize } from "@/hooks/useWindowSize";
import { useDocumentStore } from "@/stores/document";
import * as React from "react";
import { useEffect } from "react";
import { useLocation, useMatch } from "react-router-dom";
import { cn } from "../../utils/style";
import { SidebarPlugins } from "../AdvancedEditor/SidebarPlugins";
import NavigationPlugin from "../AdvancedEditor/plugins/NavigationPlugin";
import { DevToolbar } from "./DevToolbar";
import { useNavigationStore } from "./MobileNavigation";
import { Navigation } from "./Navigation";
import { useSidebarNavigationStore } from "./SidebarToggle";

type MainLayoutProps = {
  children: React.ReactNode;
};

export const MIN_NAV_WIDTH = 1100;
export const MIN_SIDEBAR_WIDTH = 800;

export const MainLayout = ({ children }: MainLayoutProps) => {
  const location = useLocation();
  const { width = 0 } = useWindowSize();
  const isDocumentRoute = useMatch("/app/documents/:docId");
  const { openSidebar, closeSidebar } = useSidebarNavigationStore();
  const { closeNav } = useNavigationStore();
  const { document: fraseDocument } = useDocumentStore();
  const prevWidthRef = React.useRef<number>(width || 0);
  const { data: subscriptionData } = useSubscription({});
  const isSubscriptionCancelled = subscriptionData?.status === "canceled";
  const env = import.meta.env.VITE_REACT_APP_ENVIRONMENT;

  useEffect(() => {
    if (isDocumentRoute) {
      openSidebar();
    } else {
      closeSidebar();
    }
  }, [location.pathname]);

  useEffect(() => {
    // Adding a tolerance of 5 pixels to account for minor mouse movements or screen adjustments
    const tolerance = 30;
    if (Math.abs(prevWidthRef.current - width) > tolerance) {
      if (width < 1025 && prevWidthRef.current > width) {
        // Check if the new width is less than the old width
        closeNav();
        closeSidebar();
      }
      prevWidthRef.current = width; // Update the ref to the new width if a significant change occurred
    }
  }, [width]);

  return (
    <div className="h-screen w-full flex flex-col overflow-y-hidden">
      <div
        className="flex-1 flex flex-col"
        style={{
          height: "calc(100vh - 4rem)",
        }}
      >
        <div
          className="flex-1 flex flex-row"
          style={{
            height: "calc(100vh - 4rem)",
          }}
        >
          <div className="print:hidden">
            <Navigation />
            <HelpCenterPanel />
            <NavigationPlugin />
          </div>

          <main
            className={cn(
              "flex-1",
              "flex-grow",
              "flex-basis-0",
              "sm:flex-basis-auto",
              "sm:w-2/6",
              "transition-all duration-100 ease-in-out"
            )}
          >
            {children}
          </main>

          {isDocumentRoute && fraseDocument && !isSubscriptionCancelled && (
            <SidebarPlugins />
          )}
        </div>
      </div>
      {env === "development" && <DevToolbar />}
    </div>
  );
};
