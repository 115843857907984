import { ContentLayout } from "@/components/Layout";
import { useGetUsersForOrg } from "@/features/users/api/getUsersForOrg";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { InputLabel } from "../../../components/Elements";
import { Skeleton } from "../../../components/Elements/Skeleton";
import { useSubscription } from "../../auth/api/getSubscription";
import { useDocumentCount } from "../../documents/api/getDocumentCount";
import { useGetAiArticleUsage } from "../../subscription/api/getAiArticleLimit";
import { useGetAiWordsForOrg } from "../../subscription/api/getAiWordsForOrg";
import { useGetSearchQueriesForUser } from "../../subscription/api/getSearchQueriesForUser";
import { getPlanNickname } from "../../subscription/utils/getPlanNickname";
import { getPricingPeriod } from "../../subscription/utils/getPricingPeriod";
import { getSearchQueryCredits } from "../../subscription/utils/getSearchQueryCredits";
import { tiers } from "../../subscription/utils/tiers";
dayjs.extend(utc);

export const Usage = () => {
  const { data: subscriptionData, isLoading: isLoadingSubscription } =
    useSubscription({});
  const { data: documentCountData, isLoading: isLoadingDocumentCount } =
    useDocumentCount({});
  const { data: usersForOrgData, isLoading: isLoadingUsersForOrg } =
    useGetUsersForOrg({});
  const { data: searchQueriesData, isLoading: isLoadingSearchQueries } =
    useGetSearchQueriesForUser();
  const { data: aiWordsData, isLoading: isLoadingAiWords } =
    useGetAiWordsForOrg();
  const { data: aiArticleUsageData, isLoading: isLoadingAiArticleUsage } =
    useGetAiArticleUsage();

  const isLoading =
    isLoadingSubscription ||
    isLoadingDocumentCount ||
    isLoadingUsersForOrg ||
    isLoadingSearchQueries ||
    isLoadingAiWords ||
    isLoadingAiArticleUsage;

  const currentMonthDisplay = dayjs().utc().format("MMMM");
  const currentMonthNumber = dayjs().utc().format("YYYY-M");

  const formatNumberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const planNickname = getPlanNickname(subscriptionData?.plan || "Free");
  const pricingPeriod =
    subscriptionData?.billing_cycle === "year" ? "yearly" : "monthly";
  const isAddOnActive = subscriptionData?.add_on;

  const subscriptionDetails = {
    plan: planNickname,
    pricingPeriod: getPricingPeriod(subscriptionData?.plan || "Free"),
    planName: subscriptionData?.plan || "Free Plan",
    proAddon: subscriptionData?.add_on ? "Active" : "Inactive",
    users: usersForOrgData?.length || 0,
    isAppsumoActive: subscriptionData?.appsumo || false,
    price:
      tiers.find((t) => t.name === planNickname)?.[pricingPeriod]?.price || 0,
    hasAddOn: !!subscriptionData?.add_on,
    documents: {
      currentMonth: documentCountData?.cur_month_doc_count || 0,
      limit: "Unlimited",
    },
    searchQueries: {
      currentMonth: searchQueriesData?.current_month?.length || 0,
      limit: getSearchQueryCredits(planNickname),
    },
    aiContent: {
      currentMonth: formatNumberWithCommas(
        aiWordsData?.["12_months"][currentMonthNumber] || 0
      ),
      limit: planNickname === "Solo" ? "20,000" : "Unlimited",
    },
    aiArticles: {
      currentMonth: aiArticleUsageData?.length || 0,
      limit: 30,
    },
  };

  return (
    <ContentLayout>
      <div className="flex flex-col items-start h-screen pt-5 px-8 w-full space-y-4 overflow-y-scroll">
        <div>
          <h2 className="text-xl dark:text-white font-medium">Usage</h2>
          <p className="text-sm text-zinc-500 dark:text-zinc-400 mt-2 min-w-[550px] mb-4">
            View your usage for the current month.
          </p>

          {(subscriptionData?.status === "active" ||
            subscriptionData?.status === "active_canceled") && (
            <section className="space-y-4">
              <h3 className="text-md dark:text-white font-medium pt-4 mt-4 border-t dark:border-t-zinc-800 w-full">
                Monthly Usage for{" "}
                {isLoading ? (
                  <Skeleton className="w-24" />
                ) : (
                  currentMonthDisplay
                )}
              </h3>
              <div>
                <InputLabel label="Documents" />
                {isLoading ? (
                  <Skeleton className="h-6 w-full" />
                ) : (
                  <p>
                    <span className="font-medium text-sm dark:text-white">
                      {subscriptionDetails.documents.currentMonth}
                    </span>{" "}
                    <span className="text-sm text-zinc-500">
                      / {subscriptionDetails.documents.limit}
                    </span>
                  </p>
                )}
              </div>

              <div>
                <InputLabel label="Search Queries" />
                {isLoading ? (
                  <Skeleton className="h-6 w-full" />
                ) : (
                  <p>
                    <span className="font-medium text-sm dark:text-white">
                      {subscriptionDetails.searchQueries.currentMonth}
                    </span>{" "}
                    <span className="text-sm text-zinc-500">
                      / {subscriptionDetails.searchQueries.limit}
                    </span>
                  </p>
                )}
              </div>

              <div>
                <InputLabel label="AI-generated Words" />
                {isLoading ? (
                  <Skeleton className="h-6 w-full" />
                ) : (
                  <p>
                    <span className="font-medium text-sm dark:text-white">
                      {subscriptionDetails.aiContent.currentMonth}
                    </span>{" "}
                    <span className="text-sm text-zinc-500">
                      / {subscriptionDetails.aiContent.limit}
                    </span>
                  </p>
                )}
              </div>

              {isAddOnActive && (
                <div>
                  <InputLabel label="AI-generated Articles" />
                  {isLoading ? (
                    <Skeleton className="h-6 w-full" />
                  ) : (
                    <p>
                      <span className="font-medium text-sm dark:text-white">
                        {subscriptionDetails.aiArticles.currentMonth}
                      </span>{" "}
                      <span className="text-sm text-zinc-500">
                        / {subscriptionDetails.aiArticles.limit}
                      </span>
                    </p>
                  )}
                </div>
              )}
            </section>
          )}
        </div>
      </div>
    </ContentLayout>
  );
};
