import {
  Button,
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  HeroPattern,
  Logo,
} from "@/components/Elements";
import { useNotificationStore } from "@/stores/notifications";
import { DialogPortal } from "@radix-ui/react-dialog";
import { useState } from "react";
import { TbArrowRight } from "react-icons/tb";
import { useNavigate } from "react-router-dom";

export const LegacyUserDialog = ({ onClose, title, text }) => {
  const [open, setOpen] = useState(true);
  const { addNotification } = useNotificationStore();
  const navigate = useNavigate();

  const close = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        if (!open) {
          onClose(close);
        }
      }}
    >
      <DialogPortal>
        <DialogContent className="p-0">
          <div className="h-full w-full bg-hero-gradient-linear">
            <div className="flex flex-col justify-center pt-4">
              <DialogCloseButton
                className={`absolute top-4 right-4`}
                close={() => {
                  onClose(close);
                }}
              />
              <HeroPattern />
            </div>
            <div>
              <DialogHeader className="pb-8 flex items-center space-x-4 pl-6">
                <Logo renderText={false} />
                <DialogTitle className="text-2xl text-zinc-700">
                  {title}
                </DialogTitle>
              </DialogHeader>
              <DialogDescription className="px-6 pb-8">
                <div className="flex flex-col space-y-4">
                  <p className="text-lg font-medium text-zinc-700 dark:text-white">
                    We're excited to announce updates about the future of Frase!
                  </p>
                  <p className="mt-4 text-sm text-zinc-600 dark:text-zinc-200">
                    You're using Frase 2.0, the new version of the Frase
                    platform. You will have access to both Frase 2.0 and the
                    legacy platform over the next few months. After this period,
                    Frase 2.0 will become the default version.
                  </p>
                  <p className="mt-2 text-sm text-zinc-600 dark:text-zinc-200">
                    Please contact us at{" "}
                    <a
                      href="mailto:team@frase.io"
                      className="underline"
                      onClick={(e) => {
                        e.preventDefault();
                        navigator.clipboard
                          .writeText("team@frase.io")
                          .then(() => {
                            addNotification({
                              type: "success",
                              title: "Email copied to clipboard",
                            });
                          });
                      }}
                    >
                      team@frase.io
                    </a>{" "}
                    if you have any questions regarding the timeline of Frase
                    2.0.
                  </p>
                </div>
              </DialogDescription>

              <DialogFooter className="px-6 pb-6 flex items-center ">
                <Button
                  variant="outlineBlur"
                  className="h-[34px]"
                  onClick={() => {
                    window.open("https://app.frase.io/", "_blank");
                  }}
                >
                  Switch to Frase Legacy
                </Button>
                <Button
                  variant="primaryBlur"
                  className="ring-2 ring-white outline-white outline-1 border border-emerald-500 text-white"
                  textClassName="text-white"
                  onClick={() => {
                    onClose(close);
                  }}
                  endIcon={<TbArrowRight />}
                >
                  Continue to Frase 2.0
                </Button>
              </DialogFooter>
            </div>
          </div>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};
