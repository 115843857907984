import { useQuery } from "react-query";

import { axios } from "@/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";

import { useDocumentStore } from "@/stores/document";
import { FraseDocument } from "../types";

export const getDocument = ({
  documentHash,
}: {
  documentHash: string;
}): Promise<FraseDocument> => {
  return axios.post(`/getDocumentByHash`, {
    doc_hash: documentHash,
  });
};

type QueryFnType = typeof getDocument;

type UseDocumentOptions = {
  documentHash: string;
  config?: QueryConfig<QueryFnType>;
};

export const useDocument = ({ documentHash, config }: UseDocumentOptions) => {
  const { setDocument } = useDocumentStore();

  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["document", documentHash],
    queryFn: () => getDocument({ documentHash }),
    onSuccess: (document) => {
      setDocument(document);
    },
  });
};
