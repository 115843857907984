import logo_dark from "@/assets/logo_color_dark.svg";
import logo_light from "@/assets/logo_color_light.svg";
import { cn } from "@/utils/style";
import { Link } from "react-router-dom";

const sizes = {
  sm: "h-6 w-auto",
  md: "h-8 w-auto",
  lg: "h-10 w-auto",
  xl: "h-12 w-auto",
};

type LogoProps = {
  renderText: boolean;
  size?: keyof typeof sizes;
  className?: string;
  imgClassName?: string;
};

export function Logo(props: LogoProps) {
  return (
    <Link
      className={cn(
        "flex flex-shrink-0 items-center justify-center text-zinc-700 dark:text-white",
        props.className
      )}
      to="."
    >
      <img
        className={cn(
          "w-auto dark:hidden",
          props.size && sizes[props.size],
          props.imgClassName
        )}
        src={logo_dark}
        alt="Workflow"
      />
      <img
        className={cn(
          "w-auto hidden dark:block",
          props.size && sizes[props.size],
          props.imgClassName
        )}
        src={logo_light}
        alt="Workflow"
      />
      {props.renderText && (
        <span className="text-2xl text-zinc-700 dark:text-white font-semibold ml-2">
          frase
        </span>
      )}
    </Link>
  );
}

Logo.defaultProps = {
  renderText: true,
  size: "md" as keyof typeof sizes,
};
