import { FraseDocument } from "..";
import { getDocument } from "../api/getDocument";

export const setLastEditor = (
  fraseDocument: FraseDocument,
  userId: number,
  fullName: string
) => {
  if (!fraseDocument) {
    return;
  }

  if (!fraseDocument.metadata) {
    fraseDocument.metadata = {};
  }

  if (userId != null) {
    fraseDocument.metadata.last_editor_id = userId;
    fraseDocument.metadata.last_editor_name = fullName;
  } else {
    fraseDocument.metadata.last_editor_id = null;
    fraseDocument.metadata.last_editor_name = null;
  }

  return fraseDocument;
};

export const isLastEditor = (
  fraseDocument: FraseDocument,
  userId: number,
  fullName: string
) => {
  if (!fraseDocument || !fraseDocument.metadata) {
    return false;
  }

  const { last_editor_id, last_editor_name } = fraseDocument.metadata;
  if (last_editor_id == null && last_editor_name == null) {
    return true;
  }

  return last_editor_id === userId || last_editor_name === fullName;
};

export const checkEditorConflict = async (
  fraseDocumentWithEditor: FraseDocument
) => {
  try {
    const { hash, metadata } = fraseDocumentWithEditor;
    const { last_editor_id, last_editor_name } = metadata;
    const fraseDocument = await getDocument({ documentHash: hash });
    const isEditor = isLastEditor(
      fraseDocument,
      last_editor_id,
      last_editor_name
    );
    if (!isEditor) {
      // If not the last editor, throw an error with the expected structure
      return Promise.reject({
        message: "not last editor",
        fullName: fraseDocument.metadata.last_editor_name,
        time: fraseDocument.lastEdited,
      });
    }
    return isEditor;
  } catch (error) {
    // If there's an error in the try block or from getDocument, reject with the structured error
    return Promise.reject({
      message: "not last editor",
      fullName: error.fullName || error?.response?.data?.last_editor_name,
      time: error.lastEdited || error?.response?.data?.lastEdited,
    });
  }
};
