import { shortenNumber } from "@/utils/number";
import { TooltipPortal } from "@radix-ui/react-tooltip";
import clsx from "clsx";
import React from "react";
import { Tag } from "..";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../Tooltip";

interface ArticleMetadataProps {
  url: string;
  urlHost: string;
  rank: number;
  wordCount: number;
  links: number;
  domainAuthority: number;
  isCustomImport?: boolean;
  className?: string;
  isProcessed?: boolean;
}

export const ArticleMetadata: React.FC<ArticleMetadataProps> = ({
  url,
  urlHost,
  rank,
  wordCount,
  links,
  domainAuthority,
  isCustomImport,
  className,
  isProcessed = true,
}) => {
  return (
    <div
      className={clsx(
        "group flex flex-shrink items-center whitespace-nowrap max-w-[90%]",
        className
      )}
    >
      {urlHost && (
        <>
          <img
            className="w-3 h-3 rounded-full bg-zinc-50 dark:bg-zinc-800"
            src={`http://www.google.com/s2/favicons?domain=${urlHost}`}
            alt=""
            onError={(e) => {
              e.currentTarget.src = "https://www.google.com/favicon.ico";
            }}
          />
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <a
                  href={url}
                  target="_blank"
                  rel="noopener"
                  className="ml-2 text-2xs text-zinc-400 dark:text-zinc-400 hover:underline hover:text-zinc-600 flex-shrink truncate"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {urlHost}
                </a>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent>{url}</TooltipContent>
              </TooltipPortal>
            </Tooltip>
          </TooltipProvider>
        </>
      )}
      {!isCustomImport && rank ? (
        <p className="ml-2 text-2xs text-zinc-400 dark:text-zinc-400">{`#${rank}`}</p>
      ) : null}
      {isCustomImport && (
        <p className="ml-2 text-2xs text-zinc-400 dark:text-zinc-400">
          Imported URL
        </p>
      )}
      {wordCount ? (
        <p className="ml-2 text-2xs text-zinc-400 dark:text-zinc-400">{`${shortenNumber(
          wordCount
        )} words`}</p>
      ) : null}
      {links ? (
        <p className="ml-2 text-2xs text-zinc-400 dark:text-zinc-400">{`${shortenNumber(
          links
        )} links`}</p>
      ) : null}
      {domainAuthority ? (
        <p className="ml-2 text-2xs text-zinc-400 dark:text-zinc-400">{`${domainAuthority} DA`}</p>
      ) : null}
      {!isProcessed && (
        <Tag
          className="ml-2 h-6 opacity-70"
          variant="small"
          color="zinc"
          textClassName="text-[11px] font-medium"
        >
          Not Processed
        </Tag>
      )}
    </div>
  );
};
