import {
  BingSearchResult,
  ProcessedBingSearchResult,
} from "../api/getBingSearchResults";
import { Article, Asset, useUrlBatch } from "../api/getUrlBatch";
import { FraseDocument } from "../types";
import { isQuestion } from "./text";
import { cleanUrl, dedupeUrls, getHostname } from "./url";

export interface SerpQuery {
  query: string;
  country: string;
  lang: string;
  count: number;
}

export const getQueries = ({
  query,
  document,
  country,
  language,
}: {
  query: string;
  document: FraseDocument;
  country: string;
  language: string;
}): SerpQuery[] => {
  const queryList = query.split(",").map((q) => q.trim());
  const domains = document.metadata.target_domain
    ? document.metadata.target_domain
        .split(",")
        .map((d) => d.trim())
        .filter(Boolean)
    : [];
  const queries: SerpQuery[] = [];

  queryList.forEach((query) => {
    const querySize = domains.length === 1 ? 20 : 10;

    if (domains && domains.length > 0) {
      domains.forEach((domain) => {
        const site = cleanUrl(domain);
        const q = `${query} site:${site}`;
        queries.push({
          query: q,
          country: country,
          lang: language,
          count: querySize,
        });
      });
    } else {
      queries.push({
        query: query,
        country: country,
        lang: language,
        count: 20,
      });
    }
  });

  return queries;
};

export const validateQueries = (queries, blacklist) => {
  const processedQueries = { ...queries };
  let questions = [];

  for (const [query, value] of Object.entries(queries)) {
    const articles = value.serp;
    questions = value.questions;
    let validResults = filterSearchResults(articles);
    validResults = dedupeUrls(validResults);
    processedQueries[query] = validResults;
    //TODO: handle get custom imports
    //TODO: handle filter blacklist
  }

  const processedResults = Object.values(processedQueries).flat();
  const processResultsUrls = processedResults
    .filter((result) => result.isValid)
    .map((result) => result.url);

  return {
    queries: processedQueries,
    results: processedResults,
    urls: processResultsUrls,
    questions,
  };
};

function validateUrlSearch(url) {
  const isValidDomain = (url, blacklist) =>
    !blacklist.some((domain) => url.startsWith(domain));

  const hasInvalidExtension = (url, extensions) =>
    extensions.some((ext) => url.endsWith(ext));

  const domainBlacklist = [
    "facebook.com",
    "linkedin.com",
    "twitter.com",
    "pinterest.com",
    "youtube.com",
    "slideshare.com",
    "apps.apple.com",
    "play.google.com",
    "reddit.com",
    "quora.com",
    "tiktok.com",
    "discord.com",
    "giphy.com",
    "yelp.com",
  ];

  const invalidExtensions = [".xml", ".pdf", ".mp3", ".mp4", ".mov"];

  url = url.toLowerCase();
  const domain = getHostname(url);

  return (
    isValidDomain(domain, domainBlacklist) &&
    !hasInvalidExtension(url, invalidExtensions)
  );
}

function parseBingUrl(url) {
  if (!url) {
    return null;
  }

  const bingIndicator = "bing.com";
  const paramIndicator = "r=";
  const paramDelimiter = "&";

  if (
    url.includes(bingIndicator) &&
    url.includes(paramIndicator) &&
    url.includes(paramDelimiter)
  ) {
    const decodedUrl = decodeURIComponent(url);
    const urlParams = decodedUrl.split(paramIndicator)[1].split(paramDelimiter);
    return urlParams[0];
  }

  return url.toString();
}

/**
 * Filters out search results.
 *
 * @param {Array} list - An array of search results with optional properties.
 * @returns {Array} items - An array of filtered search results.
 */
export const filterSearchResults = (list, blacklist) => {
  const items = [];

  list.forEach((value, index) => {
    if (value && value.url) {
      const url = parseBingUrl(value.url);
      const title = (value.name || value.title).split(" | ")[0];
      const dateCreated = value.date_created || "";
      const description = value.snippet || "";
      const isValid = validateUrlSearch(url);

      items.push({
        url,
        title,
        description,
        dateCreated,
        index,
        isValid,
      });
    }
  });

  return items;
};

export const validateQueryResults = (
  results,
  queries,
  analyzeSerp,
  setLoaderItem,
  assetServiceAlert
) => {
  // Filter valid lists, apply custom imports, and filter the blacklist
  const validList = results
    .map((list, index) => getCustomImports(queries[index], list))
    .reduce((acc, list) => acc.concat(filterBlacklist(list)), []);

  // Process the valid list if it exists
  if (validList.length > 0) {
    const dedupedResults = dedupResults(validList);
    analyzeSerp(dedupedResults);
  } else {
    setLoaderItem(null);
    assetServiceAlert();
  }
};

export const dedupResults = (list) => {
  const uniqueUrlsSet = new Set();
  const finalList = [];

  for (const value of list) {
    if (!uniqueUrlsSet.has(value.url)) {
      finalList.push(value);
      uniqueUrlsSet.add(value.url);
    }
  }

  return finalList;
};

const filterBlacklist = (list, urlBlacklist) => {
  const filteredList = list.reduce((acc, item) => {
    // Check if the URL is not previously included or not in the blacklist
    const isUrlValid =
      !acc.find((el) => el.url === item.url) && !urlBlacklist[item.url];

    // Add the item to the list if the URL is valid
    if (isUrlValid) {
      return [...acc, item];
    }

    return acc;
  }, []);

  return filteredList;
};

const getCustomImports = (query, list, document, searchQueries) => {
  const customImports = document?.metadata?.custom_imports;

  if (customImports) {
    const queryKey = `${query.query}:${query.country}:${query.lang}`;

    if (customImports[queryKey] && customImports[queryKey].length > 0) {
      const importList = customImports[queryKey].map((value) => ({
        ...value,
        valid: true,
      }));

      list = [...list, ...importList];

      if (searchQueries[queryKey]) {
        searchQueries[queryKey] = [...searchQueries[queryKey], ...importList];
      } else {
        searchQueries[queryKey] = importList;
      }
    }
  }

  return list;
};

const analyzeSerp = async (
  valid_list,
  setSerp,
  setBrief,
  stopInterval,
  assetServiceAlert,
  sourceFactory,
  currentState
) => {
  startInterval(valid_list);

  // Check items that don't have cached assets
  const urlsToProcess = valid_list
    .filter((value) => value.valid)
    .map((value) => value.url);

  const { data: resp } = await useUrlBatch({ urls: urlsToProcess });

  if (currentState.indexOf("dashboard") === -1) {
    if (resp === "error") {
      assetServiceAlert();
      stopInterval();
    } else {
      const clusters = resp.clusters;
      let error_count = 0;

      valid_list.forEach((value) => {
        if (value.imported) {
          value.index = 0; // imported URLs get position of 0
        }

        if (sourceFactory.assets[value.url]) {
          sourceFactory.assets[value.url].index = value.index; // cache original search position
          value = sourceFactory.processAssetsForSource(
            sourceFactory.assets[value.url]
          );
          value.error = false;
          value.valid = true;
        }

        if (
          !sourceFactory.assets[value.url] ||
          sourceFactory.assets[value.url].error_msg ||
          (sourceFactory.assets[value.url].assets &&
            sourceFactory.assets[value.url].assets.length === 0)
        ) {
          value.index = value.index;
          value.error = true;
          error_count += 1;
        }

        items.push(value);
      });

      if (error_count === valid_list.length) {
        assetServiceAlert();
        stopInterval();
      } else {
        setSerp();
        setBrief();
        stopInterval();
      }
    }
  }
};

/**
 * Get average word count, header count, link count, and image count across all articles
 * @param articles - Array of articles to be counted
 */
export const getArticleCounts = (articles: Article[]) => {
  let wordCount = 0;
  let headerCount = 0;
  let linkCount = 0;
  let imageCount = 0;

  articles.forEach((article) => {
    wordCount += article.word_count || 0;
    headerCount += article.assets?.length || 0;
    linkCount += article.links?.length || 0;
    imageCount += article.images?.length || 0;
  });

  return {
    wordCount: Math.round(wordCount / articles.length),
    headerCount: Math.round(headerCount / articles.length),
    linkCount: Math.round(linkCount / articles.length),
    imageCount: Math.round(imageCount / articles.length),
  };
};

/**
 * Remove asset header_tag from each article asset if it doesn't start with 'h'
 * @param articles - Array of articles to be validated
 */
export const validateArticles = (
  articles: Article[],
  searchResults: Article[]
): Article[] => {
  const updatedSearchResults = searchResults.map((searchResult) => {
    const article = articles?.find((art) => art.url === searchResult.url);
    if (article) {
      return { ...searchResult, ...article };
    }
    return searchResult;
  });

  articles?.forEach((article) => {
    article.assets &&
      article.assets.forEach((asset) => {
        if (asset.header_tag && !/^h\d/.test(asset.header_tag)) {
          delete asset.header_tag;
        }
      });
  });

  return updatedSearchResults;
};

/**
 * Get all header tags from all articles in one array
 * @param articles - Array of articles to be validated
 */
export const getHeadings = (articles: Article[]): Asset[] => {
  const headerTagAssets = [] as Asset[];

  articles.forEach((article) => {
    article.assets &&
      article.assets.forEach((asset) => {
        if (asset.header_tag) {
          headerTagAssets.push(asset);
        }
      });
  });

  return headerTagAssets;
};

/**
 * Get all questions from all articles in one array
 * @param articles - Array of articles to be validated
 */
export const getQuestions = (articles: Article[]): Asset[] => {
  const questionAssets = [] as Asset[];

  articles.forEach((article) => {
    article.assets &&
      article.assets.forEach((asset) => {
        if (isQuestion(asset.header, true)) {
          questionAssets.push(asset);
        }
      });
  });

  return questionAssets;
};

/**
 * Process Bing Search query results and return a list of items
 * @param results - Bing Search query results
 */
export const processBingSearchResults = (
  results: BingSearchResult[]
): ProcessedBingSearchResult[] => {
  const bingResults = [] as ProcessedBingSearchResult[];

  for (const [key, result] of Object.entries(results)) {
    let validItems;

    if (result.webPages) {
      validItems = result.webPages.value;
    } else if (result.value) {
      validItems = result.value;
    }

    const filteredItems = filterBingSearchResults(validItems);
    bingResults.push(...filteredItems);
  }

  return bingResults;
};

/**
 * Filter Bing Search results and return a list of filtered items
 * @param items - Bing Search results
 */
export const filterBingSearchResults = (
  results: BingSearchResult[]
): ProcessedBingSearchResult[] => {
  const filteredResults = [] as ProcessedBingSearchResult[];

  results.forEach((value) => {
    if (value && value.url) {
      const url = parseBingUrl(value.url);
      const title = (value.name || value.title).split(" | ")[0];
      const description = value.snippet || "";
      const isValid = validateUrlSearch(url);

      if (isValid) {
        filteredResults.push({
          url,
          title,
          description,
        });
      }
    }
  });

  return filteredResults;
};
