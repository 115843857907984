const storagePrefix = "frase_";
const LAST_VISITED_PATH_KEY = "lastVisitedPath";

const storage = {
  getToken: () => {
    const token = window.localStorage.getItem(`${storagePrefix}token`);
    return token ? JSON.parse(token) : null;
  },
  setToken: (token: string) => {
    window.localStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
  },
  setRefreshToken: (token: string) => {
    window.localStorage.setItem(
      `${storagePrefix}refreshToken`,
      JSON.stringify(token)
    );
  },
  getRefreshToken: () => {
    const refreshToken = window.localStorage.getItem(
      `${storagePrefix}refreshToken`
    );
    return refreshToken ? JSON.parse(refreshToken) : null;
  },
  clearToken: () => {
    window.localStorage.removeItem(`${storagePrefix}token`);
    window.localStorage.removeItem(`${storagePrefix}refreshToken`);
  },
  setLastVisitedPath(path: string) {
    localStorage.setItem(LAST_VISITED_PATH_KEY, path);
  },
  getLastVisitedPath() {
    return localStorage.getItem(LAST_VISITED_PATH_KEY);
  },
  clearLastVisitedPath() {
    localStorage.removeItem(LAST_VISITED_PATH_KEY);
  },
};

export default storage;
