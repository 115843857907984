import { useEditorStore } from "@/stores/editor";
import { FraseDocument } from "..";

export const getFraseDocumentTitle = (fraseDocument: FraseDocument): string => {
  const { editor: editorStore } = useEditorStore();
  const { activeTabIndex } = editorStore;

  const documentTitle = fraseDocument?.text?.[activeTabIndex]?.title || "";

  return documentTitle;
};
