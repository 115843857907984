import { useNewDocumentStore } from "@/components/Elements";
import { API_URL, SERVICE_URL } from "@/config";
import * as Sentry from "@sentry/react";
import Axios, { AxiosInstance } from "axios";
import { refreshAccessToken } from "../features/auth/api/refreshToken";
import { useNotificationStore } from "../stores/notifications";
import { useDialogStore } from "../stores/upgrade";
import storage from "../utils/storage";

export const limitStatusMessages = {
  "Free trial limit reached": {
    "/getLiveSearchResults":
      "You have reached the limit of search queries for your free trial. Please upgrade to a plan to continue.",
    "/createDocument":
      "You have reached the limit of documents for your free trial. Please upgrade to a plan to continue.",
    "/gpt_streaming":
      "You have reached the limit of AI writer requests for your free trial. Please upgrade to a plan to continue.",
    "/gpt_article":
      "You have reached the limit of AI article wizard documents for your free trial. Please upgrade to a plan to continue.",
  },
  "Plan limit reached": {
    "/getLiveSearchResults":
      "You have reached the limit of search queries for your plan. Please upgrade your plan to continue.",
    "/createDocument":
      "You have reached the limit of documents for your plan. Please upgrade your plan to continue.",
    "/gpt_streaming":
      "You have reached the limit of AI writer requests for your plan. Please upgrade your plan to continue.",
    "/gpt_article":
      "You have reached the limit of AI article wizard documents for your plan. Please upgrade your plan to continue.",
  },
  "AI article generation limit reached": {
    "/gpt_article":
      "You have reached the limit of AI article wizard documents for your plan. AI article wizard credits reset on the first of each month. There is a limit of 30 article generations per month.",
  },
};

let refreshPromise: Promise<string> | null = null;

const clearRefreshTokenPromise = () => {
  refreshPromise = null;
};

let refreshQueue: Array<(value: string) => void> = [];

async function refreshTokenOnce(): Promise<string> {
  if (!refreshPromise) {
    const refreshToken = storage.getRefreshToken();
    if (refreshToken) {
      refreshPromise = refreshAccessToken(refreshToken)
        .then(({ accessToken }) => {
          storage.setToken(accessToken);
          refreshQueue.forEach((resolve) => resolve(accessToken));
          refreshQueue = [];
          return accessToken;
        })
        .catch((error) => {
          refreshQueue.forEach((resolve) => resolve(error));
          refreshQueue = [];
          throw error;
        })
        .finally(clearRefreshTokenPromise);
    } else {
      console.error("No refresh token found");
      throw new Error("No refresh token found");
    }
  }

  return new Promise((resolve) => {
    refreshQueue.push(resolve);
  });
}

const createAxiosInstance = (baseURL: string): AxiosInstance => {
  const skewProtectionHeaders =
    process.env.VERCEL_SKEW_PROTECTION_ENABLED === "1"
      ? { "x-deployment-id": process.env.VERCEL_DEPLOYMENT_ID }
      : {};

  const instance = Axios.create({
    baseURL,
    headers: {
      Accept: "application/json",
      ...skewProtectionHeaders,
    },
  });
  instance.interceptors.request.use((config) => {
    const token = storage.getToken();
    if (token) {
      config.headers.authorization = token;
    }
    // Merge with skew protection headers if they exist
    Object.assign(config.headers, skewProtectionHeaders);
    return config;
  });
  // src/lib/axios.ts

  instance.interceptors.response.use(
    (response) => response.data,
    async (error) => {
      if (Axios.isCancel(error)) {
        return Promise.resolve();
      }

      if (/Loading chunk [\d]+ failed/.test(error.message)) {
        console.error("Chunk Load Failed", error);
        // Handle the chunk load failure
        // For example, you can reload the page or redirect the user
        window.location.reload();
      }

      const originalRequest = error.config;
      if (error.response?.status === 498) {
        // Token expired
        if (!originalRequest._retry) {
          originalRequest._retry = true;
          try {
            const accessToken = await refreshTokenOnce();
            originalRequest.headers.authorization = accessToken;
            return instance(originalRequest);
          } catch (refreshError) {
            storage.clearToken();
            // Redirect to login page
            window.location.assign("/login");
            return Promise.reject(refreshError);
          }
        }
      } else if (error.response?.status === 401) {
        // If the URL includes "/login", it means the login attempt failed
        if (originalRequest.url.includes("/login")) {
          useNotificationStore.getState().addNotification({
            type: "error",
            title: "Login failed",
            message: "Incorrect email or password.",
          });
          return Promise.reject(error);
        }

        // If the URL includes "/refreshToken", it means the refresh attempt failed
        if (originalRequest.url.includes("/refreshToken")) {
          storage.clearToken();
          // Redirect to login page
          window.location.assign("/login");
          return Promise.reject(error);
        }

        if (!originalRequest._retry) {
          originalRequest._retry = true;
          try {
            const accessToken = await refreshTokenOnce();
            originalRequest.headers.authorization = accessToken;
            return instance(originalRequest);
          } catch (refreshError) {
            storage.clearToken();
            // Redirect to login page
            window.location.assign("/login");
            return Promise.reject(refreshError);
          }
        }
      }

      if (
        error.response?.status === 429 &&
        originalRequest.url.includes("sentry")
      ) {
        console.error("Sentry rate limit reached");
        return Promise.reject(error);
      }

      if (error.response?.status === 403) {
        const requestUrl = error.response?.config.url;
        const statusMessage = error.response?.data?.status;
        const limitMessage =
          statusMessage && limitStatusMessages[statusMessage]
            ? limitStatusMessages[statusMessage][
                requestUrl as keyof (typeof limitStatusMessages)[typeof statusMessage]
              ]
            : "Please upgrade your plan to continue.";

        // Close any open dialogs
        useDialogStore.getState().closeDialog();

        // Open the appropriate upgrade dialog based on the limit reached
        if (statusMessage === "Free trial limit reached") {
          useDialogStore.getState().openDialog("freeTrialLimit", limitMessage);
        } else if (statusMessage === "Plan limit reached") {
          useDialogStore.getState().openDialog("planLimit", limitMessage);
        } else if (statusMessage === "AI article generation limit reached") {
          useNewDocumentStore.getState().setOpen(false);
          useDialogStore.getState().openDialog("aiArticleLimit", limitMessage);
        }

        return Promise.reject(error);
      }

      if (
        error.response?.status === 400 &&
        originalRequest.url.includes("/gpt_urls")
      ) {
        return Promise.reject(error);
      }

      if (
        error.response?.status === 400 &&
        originalRequest.url.includes("/register")
      ) {
        return Promise.reject(error);
      }

      /*useNotificationStore.getState().addNotification({
        type: "error",
        title: "Server Error",
        message:
          error.response?.data?.message || "An unexpected error occurred.",
      });*/
      Sentry.configureScope((scope) => {
        scope.setExtra("request", error.config);
      });
      return Promise.reject(error);
    }
  );
  return instance;
};

export const CancelToken = Axios.CancelToken;
export const axios = createAxiosInstance(API_URL);
export const axiosService = createAxiosInstance(SERVICE_URL);
