import { FraseDocument } from "@/features/documents";
import { useEditorStore } from "@/stores/editor";
import { $generateNodesFromDOM } from "@lexical/html";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $createParagraphNode, $getRoot, $insertNodes } from "lexical";
import { useMemo } from "react";
import { getWordCountOfTextNode } from "../WordCountPlugin";

const CONTENT_LABEL = "Tab 1";

export default function PrepopulateDocument({
  fraseDocument,
}: {
  fraseDocument: FraseDocument;
}) {
  const [editor] = useLexicalComposerContext();
  const { editor: editorStore, setEditor } = useEditorStore();
  const { activeTabIndex, isRefreshingDocument } = editorStore;

  useMemo(() => {
    editor.update(() => {
      if (!fraseDocument || fraseDocument.text === null) {
        return;
      }
      // get array item that has name property that equals "my content"
      let documentContent = {
        html: "",
        title: "",
        name: CONTENT_LABEL,
      };
      documentContent = fraseDocument.text[activeTabIndex] || documentContent;

      const parser = new DOMParser();
      const dom = parser.parseFromString(documentContent.html, "text/html");
      // Once you have the DOM instance it's easy to generate LexicalNodes.

      let nodes = $generateNodesFromDOM(editor, dom);
      // Select the root
      $getRoot().select();

      // Clear the root
      $getRoot().clear();
      // If nodes is empty, insert a placeholder paragraph

      if (nodes.length === 0) {
        nodes.push($createParagraphNode());
      }
      // Insert the nodes

      $insertNodes(nodes);

      setEditor((editorState) => {
        editorState.isPopulated = true;
      });
      // Set cursor at the start of the document

      $getRoot().selectStart();
      const root = $getRoot();
      const text = root.getTextContent();
      (async () => {
        const numWords = await getWordCountOfTextNode(text);
        setEditor((editorState) => {
          editorState.wordCount = numWords;
          editorState.isRefreshingDocument = false;
        });
      })();
    });
  }, [
    fraseDocument.hash,
    activeTabIndex,
    fraseDocument.text.length,
    isRefreshingDocument,
  ]);

  return null;
}
