import {
  Button,
  Combobox,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Input,
  SemiCircleProgress,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/Elements";

import { useNotificationStore } from "@/stores/notifications";
import { cn } from "@/utils/style";
import { debounce } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import {
  TbArrowRight,
  TbClipboard,
  TbDots,
  TbDownload,
  TbHighlight,
  TbInfoCircle,
  TbListCheck,
  TbPointFilled,
  TbSearch,
} from "react-icons/tb";
import { useEditorStore } from "../../../../stores/editor";
import { DialogModal, Textarea } from "../../../Elements";
import { useHeaderNavigation } from "../../hooks/useHeaderNavigation";
import { sanitizeUrl, validateUrl } from "../../utils/url";
import { Insights } from "../InsightsPlugin/Insights";
import { ClusterCard } from "./ClusterCard";
import TopicBlacklist from "./TopicBlacklist";
import { TopicCard } from "./TopicCard";
import TopicHeatmap from "./TopicHeatmap";

const ImportTopicsButton = ({ setCustomTopics }) => {
  const [open, setOpen] = useState(false);
  const [urls, setUrls] = useState("");
  const { addNotification } = useNotificationStore();

  const handleSubmit = () => {
    const processedUrls = urls.split(",").map((url) => url.trim());
    let valid = true;
    let invalidUrls = [] as string[];
    processedUrls.forEach((url) => {
      if (!validateUrl(url)) {
        valid = false;
        invalidUrls.push(url);
      }
      url = sanitizeUrl(url);
    });

    if (!valid) {
      addNotification({
        title: "Invalid URL",
        message: `The following URLs are invalid: ${invalidUrls.join(
          ", "
        )}. The supported protocols are https and http.`,
        type: "error",
      });
      return;
    }

    setCustomTopics(processedUrls);
    setOpen(false);
  };

  return (
    <DialogModal
      open={open}
      setOpen={setOpen}
      trigger={
        <Button variant="outlineBlur" size="xs" className="h-7">
          Import Topics
        </Button>
      }
      className="gap-0"
      variant="buttonIcon"
      title="Import Topics"
      footer={
        <div className="mt-4 text-center flex-col">
          <Button
            type="submit"
            variant="primaryBlur"
            disabled={urls.length === 0}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </div>
      }
    >
      <Textarea
        value={urls}
        onChange={(e) => setUrls(e.target.value)}
        placeholder="https://www.example1.com, https://www.example2.com..."
        className="h-24 resize-none"
      />
    </DialogModal>
  );
};

export function TopicListMenu({
  setBlacklistVisible,
  commaSeparatedTopics,
  topicsCsvData,
}) {
  const { setHeaderNavigation } = useHeaderNavigation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { editor: editorStore, setEditor } = useEditorStore();
  const { highlightTopics } = editorStore;

  const { addNotification } = useNotificationStore();
  const handleRenderPlaceholderFeature = () => {
    addNotification({
      title: "Feature in progress",
      message: "Please check back later.",
      type: "info",
    });
  };

  const handleNotifyCopyTopics = () => {
    addNotification({
      title: "Copied to clipboard",
      message: "Topics copied to clipboard",
      type: "success",
    });
  };

  return (
    <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
      <DropdownMenuTrigger>
        <Button
          variant="buttonIcon"
          size="sm"
          className="ml-1.5"
          buttonIcon={<TbDots />}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        ></Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuGroup
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {/*<DropdownMenuItem
            onClick={(event) => {
              event.stopPropagation();
              handleRenderPlaceholderFeature();
            }}
          >
            <TbTextPlus className="mr-2" />
            Import custom topics
          </DropdownMenuItem>*/}
          <DropdownMenuItem
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <TbDownload className="mr-2" />
            <CSVLink
              data={topicsCsvData}
              filename="topics.csv"
              className="w-full h-full"
            >
              Export to CSV
            </CSVLink>
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={(event) => {
              event.stopPropagation();
              navigator.clipboard.writeText(commaSeparatedTopics);
              handleNotifyCopyTopics();
            }}
          >
            <TbClipboard className="mr-2" />
            Copy to clipboard
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={(event) => {
              event.stopPropagation();
              setBlacklistVisible(true);
              setHeaderNavigation({
                title: "Topic Blacklist",
                onBack: () => setBlacklistVisible(false),
              });
            }}
          >
            <TbListCheck className="mr-2" />
            Manage blacklist
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={(event) => {
              event.stopPropagation();
              setEditor((editorState) => {
                editorState.highlightTopics = !editorState.highlightTopics;
              });
            }}
          >
            <TbHighlight className="mr-2" />
            {highlightTopics ? "Hide" : "Show"} topics in editor
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export default function TopicList({
  filteredTopics,
  onFilterChange,
  setSelectedTopic,
  score,
  avgScore,
  selectedType,
  selectedStatus,
  setSelectedType,
  setSelectedStatus,
  setCompetitorsVisible,
  panelWidth,
  document,
}) {
  const [blacklistVisible, setBlacklistVisible] = useState(false);
  const [isInsightsStickky, setIsInsightsSticky] = useState(false);
  const insightsRef = useRef<HTMLDivElement>(null);
  const commaSeparatedTopics = useMemo(
    () =>
      filteredTopics && filteredTopics.map((topic) => topic.entity).join(", "),
    [filteredTopics]
  );
  const topicsCsvData = useMemo(
    () =>
      filteredTopics && [
        ["Topic", "Frequency", "User Count"],
        ...filteredTopics.map((topic) => [
          topic.entity,
          topic.frequency,
          isNaN(topic.user_count) ? 0 : topic.user_count,
        ]),
      ],
    [filteredTopics]
  );

  const handleTypeChange = (value) => {
    setSelectedType(value);
  };

  const handleStatusChange = (value) => {
    setSelectedStatus(value);
  };

  const typeOptions = useMemo(
    () => [
      { label: "Long Tail", value: "longTail" },
      { label: "All Topics", value: "topTopics" },
      { label: "Clusters", value: "clusters" },
    ],
    []
  );

  const statusOptions = useMemo(
    () => [
      { label: "All", value: "all", color: "text-zinc-500" },
      { label: "Completed", value: "completed", color: "text-emerald-500" },
      { label: "In Progress", value: "inProgress", color: "text-amber-500" },
      { label: "Overuse", value: "overuse", color: "text-rose-500" },
      { label: "Topic Gap", value: "topicGap", color: "text-zinc-500" },
    ],
    []
  );

  const renderClusterCards = useMemo(
    () =>
      filteredTopics.map((item) => (
        <ClusterCard
          item={item}
          setSelectedTopic={setSelectedTopic}
          key={item.label} // Use item.entity instead of index as key
          count={item.user_count}
          total={item.frequency}
          topic={item.label}
          color={item.bg_color}
        />
      )),
    [filteredTopics, setSelectedTopic]
  );

  const renderTopicCards = useMemo(
    () =>
      filteredTopics.map((item) => (
        <TopicCard
          sources={item.sources}
          item={item}
          setSelectedTopic={setSelectedTopic}
          key={item.entity} // Use item.entity instead of index as key
          count={item.user_count}
          total={item.frequency}
          topic={item.entity}
          color={item.bg_color}
        />
      )),
    [filteredTopics, setSelectedTopic]
  );

  const renderTopicScore = () => {
    return (
      <div className="flex flex-col w-full items-center">
        <div className="flex gap-1.5 w-full">
          <h1 className="text-xs font-semibold text-zinc-800 whitespace-nowrap dark:text-white">
            Topic Score
          </h1>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <Button
                  variant="floatingIcon"
                  className="text-zinc-800 dark:text-zinc-200"
                  buttonIcon={<TbInfoCircle />}
                ></Button>
              </TooltipTrigger>
              <TooltipContent>
                Topic Score is a measure of how well your content represents the
                topics that you are targeting.
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
        <div className="transition-all text-zinc-800 dark:text-zinc-200 z-50 h-16 w-32 self-center">
          <SemiCircleProgress progress={score} />
        </div>

        <div className="flex-col flex w-full text-center items-center">
          <div className="flex mt-3 gap-x-1">
            <p className="text-zinc-600 font-medium text-2xs dark:text-white">
              Avg Score
            </p>
            <p className="font-medium text-2xs text-zinc-600 dark:text-white">
              {Math.floor(avgScore)}
            </p>
          </div>
          <Button
            variant="floatingIcon"
            className="text-zinc-800 dark:text-zinc-200"
            size="xs"
            endIcon={<TbArrowRight />}
            onClick={() => setCompetitorsVisible(true)}
          >
            View competitors
          </Button>
        </div>
      </div>
    );
  };

  const renderTypeCombobox = () => {
    return (
      <div className="flex-col items-center">
        <p className="text-zinc-500 font-medium dark:text-white text-2xs mt-1 mr-2">
          Type
        </p>
        <Combobox
          value={selectedType}
          className="border border-zinc-900/10 dark:border-white/10 rounded-md h-6 mt-1"
          options={typeOptions.map((option) => ({
            ...option,
            onClick: () => handleTypeChange(option.value),
          }))}
        />
      </div>
    );
  };

  const renderStatusCombobox = () => {
    return (
      <div className="flex-col items-center">
        <p className="text-zinc-500 font-medium dark:text-white text-2xs mt-1 mr-2">
          Status
        </p>
        <Combobox
          value={selectedStatus}
          className="border border-zinc-900/10 dark:border-white/10 rounded-md h-6 mt-1"
          options={statusOptions.map((option) => ({
            ...option,
            icon: <TbPointFilled className={cn("mr-2", option.color)} />,
            onClick: () => handleStatusChange(option.value),
          }))}
        />
      </div>
    );
  };

  const renderEmptyState = () => {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <p className="text-zinc-500 dark:text-white text-sm">No topics found</p>
      </div>
    );
  };

  const renderTopics = () => {
    if (selectedType === "clusters") {
      return (
        <ul className="grid gap-2 mt-4 pb-48">
          {filteredTopics.length > 0 ? renderClusterCards : renderEmptyState()}
        </ul>
      );
    }

    return (
      <ul className="grid gap-2 mt-4 pb-48">
        {filteredTopics.length > 0 ? renderTopicCards : renderEmptyState()}
      </ul>
    );
  };

  const checkInsightsVisibility = () => {
    if (insightsRef.current) {
      const rect = insightsRef.current.getBoundingClientRect();
      setIsInsightsSticky(rect.y < 98);
    }
  };

  const debouncedCheckVisibility = debounce(checkInsightsVisibility, 100);

  useEffect(() => {
    const handleScroll = () => {
      debouncedCheckVisibility();
    };

    window.addEventListener("scroll", handleScroll, true);
    return () => {
      window.removeEventListener("scroll", handleScroll, true);
      debouncedCheckVisibility.cancel();
    };
  }, []);

  if (blacklistVisible) {
    return <TopicBlacklist setBlacklistVisible={setBlacklistVisible} />;
  }

  return (
    <div>
      <div className="pl-4 pr-1">{renderTopicScore()}</div>
      <div
        className={cn(
          "w-full py-4 sticky top-0 bg-white dark:bg-zinc-900 z-50",
          isInsightsStickky &&
            "transition-all border-b dark:border-b-zinc-800 shadow-sm dark:shadow-md"
        )}
        ref={insightsRef}
      >
        <Insights />
      </div>
      <div className="flex items-start justify-between pb-4 pl-4 pr-1">
        <div className="flex items-center gap-x-4">
          {renderTypeCombobox()}
          {renderStatusCombobox()}
        </div>
        <div className="flex items-center mt-auto ">
          <TopicHeatmap panelWidth={panelWidth} />
          <TopicListMenu
            setBlacklistVisible={setBlacklistVisible}
            commaSeparatedTopics={commaSeparatedTopics}
            topicsCsvData={topicsCsvData}
          />
        </div>
      </div>

      <div className="pl-4 pr-1">
        <Input
          onChange={onFilterChange}
          placeholder="Filter topics..."
          className="bg-zinc-50"
          startIcon={<TbSearch />}
          startIconClassName="text-zinc-500"
        />
      </div>

      <div className="pl-4 pr-1">{renderTopics()}</div>
    </div>
  );
}
