import { Button, Checkbox, Textarea } from "@/components/Elements";
import { CustomizeResultsCard } from "@/components/Elements/Card/CustomizeResultsCard";
import { useUrlBatch } from "@/features/documents/api/getUrlBatch";
import { useUpdateDocument } from "@/features/documents/api/updateDocument";
import {
  getArticleCounts,
  getHeadings,
  getQuestions,
  validateArticles,
} from "@/features/documents/utils/research";
import { processBrief } from "@/features/documents/utils/serp";
import { scoreSERP } from "@/features/documents/utils/topics";
import { useDocumentStore } from "@/stores/document";
import { useNotificationStore } from "@/stores/notifications";
import { useSerpStore } from "@/stores/serp";
import { cn } from "@/utils/style";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../Elements";
import { LogoSpinner } from "../../../Elements/Spinner/LogoSpinner";
import { useHeaderNavigation } from "../../hooks/useHeaderNavigation";
import { sanitizeUrl, validateUrl } from "../../utils/url";

export const ImportUrlButton = ({ open, setOpen, setCustomUrls, trigger }) => {
  const [importUrls, setImportUrls] = useState("");
  const [sanitizedImportUrls, setSanitizedImportUrls] = useState(
    [] as string[]
  );
  const [isProcessingUrl, setIsProcessingUrl] = useState(false);
  const { addNotification } = useNotificationStore();
  const { document: fraseDocument } = useDocumentStore();
  const { serp, setSerp } = useSerpStore();
  const [triggerRefetch, setTriggerRefetch] = useState(false);

  const { urls, results, articles, allArticles } = serp[fraseDocument.id] || {
    urls: [],
    results: [],
    articles: [],
    allArticles: [],
  };
  const validArticles = React.useMemo(() => {
    return (articles || [])
      .filter((article) => article.isValid)
      .sort((a, b) => a.index - b.index);
  }, [articles]);
  const blacklist = fraseDocument.metadata?.blacklist || {};

  const urlBatchQuery = useUrlBatch({
    urls: [
      ...new Set([
        ...sanitizedImportUrls,
        ...validArticles.map((article) => article.url),
      ]),
    ],
    document: fraseDocument,
    shouldSetSerpProcessed: true,
    config: {
      enabled: triggerRefetch,
    },
  });

  const handleSubmit = async () => {
    setIsProcessingUrl(true);
    const processedUrls = importUrls
      .split(",")
      .map((url) => sanitizeUrl(url.trim()));
    let valid = true;
    let invalidUrls = [];
    let existingUrls = [];

    processedUrls.forEach((url) => {
      if (validArticles.some((article) => article.url === url)) {
        existingUrls.push(url);
      } else if (!validateUrl(url)) {
        valid = false;
        invalidUrls.push(url);
      }
    });

    if (existingUrls.length > 0) {
      addNotification({
        title: "URL already imported",
        message: `The following URLs have already been processed: ${existingUrls.join(
          ", "
        )}.`,
        type: "info",
      });
      setIsProcessingUrl(false);
      return;
    }

    if (!valid) {
      addNotification({
        title: "Invalid URL",
        message: `The following URLs are invalid: ${invalidUrls.join(
          ", "
        )}. The supported protocols are https and http.`,
        type: "error",
      });
      setIsProcessingUrl(false);
      return;
    }

    setSanitizedImportUrls(processedUrls);
    setTriggerRefetch(true);
  };

  useEffect(() => {
    if (urlBatchQuery.isSuccess && triggerRefetch) {
      const { items, cluster_info } = urlBatchQuery.data;
      let searchResults = [...serp[fraseDocument.id].allArticles] as object[];
      let importedResults = [] as object[];

      items &&
        items.forEach((item) => {
          const index = searchResults.findIndex(
            (result) => result.url === item.url
          );
          if (index === -1) {
            importedResults.push({
              title: item.title,
              url: item.url,
              description: item.description,
              isValid: true,
              isCustomImport: true,
              index: 0,
              dateCreated: "",
            });
          }
        });

      searchResults = [...importedResults, ...searchResults];
      const unfilteredResults = searchResults;

      searchResults = searchResults.filter((result) => {
        return !Object.keys(blacklist).includes(result.url);
      });

      const articles = validateArticles(items, searchResults);
      const { wordCount, headerCount, linkCount, imageCount } =
        getArticleCounts(articles);
      const headings = getHeadings(articles);
      const serpQuestions = getQuestions(articles);
      const { domain_map, topics } = processBrief(
        items,
        cluster_info,
        fraseDocument.query,
        fraseDocument.metadata?.lang_code
      );

      setSerp(fraseDocument.id, {
        ...serp[fraseDocument.id],
        results: searchResults,
        allArticles: unfilteredResults,
        articles: articles || [],
        clusters: cluster_info,
        headings: headings,
        topics: topics,

        domainMap: domain_map,
        questions: {
          ...serp[fraseDocument.id].questions,
          serp: serpQuestions,
        },
        averageWordCount: wordCount,
        averageHeaderCount: headerCount,
        averageImageCount: imageCount,
        averageLinkCount: linkCount,
        urls:
          allArticles
            .map((article) => article.url)
            .filter((url) => !blacklist[url]) || [],
        serpLoaded: true,
      });
      setIsProcessingUrl(false);
      setCustomUrls(sanitizedImportUrls);
      setImportUrls("");
      setSanitizedImportUrls([]);
      setOpen(false);
      setTriggerRefetch(false);
    }
  }, [urlBatchQuery.isSuccess]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {trigger || (
          <Button variant="outlineBlur" size="xs" className="h-7">
            Import URL
          </Button>
        )}
      </DialogTrigger>
      <DialogContent>
        {isProcessingUrl ? (
          <div className="flex items-center justify-center py-16 pb-24">
            <LogoSpinner
              variant="md"
              loadingText="Importing URL..."
            ></LogoSpinner>
          </div>
        ) : (
          <>
            <DialogHeader className="pb-0 flex items-center justify-between">
              <DialogTitle>Import URL</DialogTitle>
              <DialogCloseButton
                close={() => {
                  setOpen(false);
                }}
              />
            </DialogHeader>
            <DialogDescription className="overflow-y-hidden px-4">
              <Textarea
                value={importUrls}
                onChange={(e) => setImportUrls(e.target.value)}
                placeholder="https://www.example1.com"
                className="h-24 resize-none"
                onClick={(e) => e.stopPropagation()}
              />
            </DialogDescription>
            <DialogFooter className="px-4 pb-4 text-center flex-col">
              <Button
                type="submit"
                variant="primaryBlur"
                disabled={importUrls.length === 0}
                onClick={handleSubmit}
              >
                Save
              </Button>
            </DialogFooter>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

const ControlButtons = ({
  saveDisabled,
  selectedArticlesLength,
  validArticlesLength,
  handleSelectAll,
  handleSelectNone,
  handleSaveBlacklist,
  setCustomUrls,
}) => {
  const [allResultsSelected, setAllResultsSelected] = useState(true);
  const [importUrlDialogOpen, setImportUrlDialogOpen] = useState(false);

  return (
    <div className="flex flex-col w-full">
      <div className="flex items-center w-full justify-between">
        <div className="flex items-center pl-1.5">
          <div className="flex-col pt-2.5">
            <span className="font-semibold text-zinc-800 dark:text-zinc-200 pl-2">
              {selectedArticlesLength}
            </span>
            <span className="ml-1 text-xs text-zinc-500 whitespace-nowrap mr-4">
              of {validArticlesLength} results selected
            </span>
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <ImportUrlButton
            setCustomUrls={setCustomUrls}
            open={importUrlDialogOpen}
            setOpen={setImportUrlDialogOpen}
          />
          <Button
            variant="primaryBlur"
            size="xs"
            className="h-7"
            onClick={() => {
              handleSaveBlacklist();
            }}
            disabled={saveDisabled}
          >
            Save
          </Button>
        </div>
      </div>
      <div className="flex items-center border-t border-zinc-900/5 dark:border-zinc-800 pt-2 pb-1 mt-2">
        <Checkbox
          className={cn(
            "ml-4 mr-3",
            selectedArticlesLength === validArticlesLength &&
              "bg-emerald-600 dark:bg-emerald-600 hover:dark:bg-emerald-600"
          )}
          checked={allResultsSelected}
          onCheckedChange={(checked) => {
            if (checked) {
              handleSelectAll();
            } else {
              handleSelectNone();
            }
            setAllResultsSelected(checked);
          }}
          aria-label="Select all results"
        />
        <div className="flex-col">
          <span className="ml-1 text-xs text-zinc-500 whitespace-nowrap">
            Page title
          </span>
        </div>
      </div>
    </div>
  );
};

export default function CustomizeResults({
  setCustomizeResultsVisible,
  setIsLoadingSerp,
}: {
  setCustomizeResultsVisible: (value: boolean) => void;
  setIsLoadingSerp: (value: boolean) => void;
}) {
  const { document: fraseDocument } = useDocumentStore();
  const { serp, setSerp } = useSerpStore();
  const docId = fraseDocument.id;
  const currentSerp = serp[docId] || {
    results: [],
    articles: [],
    allArticles: [],
    serpLoaded: false,
  };
  const { setHeaderNavigation, onBack } = useHeaderNavigation();
  const blacklist = fraseDocument.metadata?.blacklist || {};
  const custom_imports = fraseDocument.metadata?.custom_imports || {};
  const updateDocumentMutation = useUpdateDocument({
    notifyOnSuccess: false,
  });
  const { addNotification } = useNotificationStore();
  const [tempBlacklist, setTempBlacklist] = useState(blacklist);
  const [customUrls, setCustomUrls] = useState([]);

  const validArticles = React.useMemo(() => {
    return (currentSerp.articles || [])
      .filter((article) => article.isValid)
      .sort((a, b) => a.index - b.index);
  }, [currentSerp.articles]);

  const allArticles = React.useMemo(() => {
    const customImportKey = `${fraseDocument.query}:${fraseDocument.metadata.code}:${fraseDocument.metadata.lang_code}`;
    const combinedArticles = (custom_imports[customImportKey] || []).concat(
      (currentSerp.allArticles || []).filter((article) => article.isValid)
    );

    const uniqueArticles = combinedArticles.filter(
      (article, index, self) =>
        index === self.findIndex((t) => t.url === article.url)
    );

    return uniqueArticles.sort((a, b) => a.index - b.index);
  }, [
    currentSerp.allArticles,
    custom_imports,
    fraseDocument.query,
    fraseDocument.metadata.code,
    fraseDocument.metadata.lang_code,
  ]);

  useEffect(() => {
    setHeaderNavigation({
      title: "Customize search results",
      onBack: () => {
        handleNavigateBack();
      },
    });
  }, [setCustomizeResultsVisible]);

  // Call the useUrlBatch hook at the top level of your component
  const urlBatchQuery = useUrlBatch({
    urls:
      allArticles
        .map((article) => article.url)
        .filter((url) => !tempBlacklist[url]) || [],
    document: fraseDocument,
    shouldSetSerpProcessed: true,
    config: {
      enabled: false,
    },
  });

  const handleNavigateBack = () => {
    setCustomizeResultsVisible(false);
    onBack();
  };

  const handleSaveBlacklist = () => {
    setIsLoadingSerp(true); // This will show the loader
    if (allArticles.length - Object.keys(tempBlacklist).length < 5) {
      addNotification({
        title: "Customize search results",
        message: "Please select at least 5 results.",
        type: "error",
      });
      setIsLoadingSerp(false); // This will hide the loader
      return;
    }

    let newDocument = {
      ...fraseDocument,
      metadata: {
        ...fraseDocument.metadata,
        blacklist: tempBlacklist,
      },
    };

    if (customUrls.length > 0) {
      const serpKey = `${fraseDocument.query}:${fraseDocument.metadata.code}:${fraseDocument.metadata.lang_code}`;

      // Ensure custom_imports is an object before attempting to access serpKey
      const customImports = newDocument.metadata.custom_imports || {};

      newDocument = {
        ...newDocument,
        metadata: {
          ...newDocument.metadata,
          custom_imports: {
            ...customImports,
            [serpKey]: [
              ...(customImports[serpKey] || []),
              ...validArticles
                .filter((article) => customUrls.includes(article.url))
                .map((article) => ({
                  title: article.title,
                  url: article.url,
                  description: article.description,
                  isValid: true,
                  isCustomImport: true,
                  index: 0,
                  dateCreated: "",
                })),
            ],
          },
        },
      };
    }

    updateDocumentMutation.mutate(newDocument);

    urlBatchQuery
      .refetch()
      .then(async (response) => {
        if (!response.data) {
          setIsLoadingSerp(false); // This will hide the loader
          return;
        }
        const { items, cluster_info } = response.data;
        const dedupedItems = items.filter(
          (item, index, self) =>
            index ===
            self.findIndex(
              (t) => t.url === item.url && !tempBlacklist[item.url]
            )
        );

        let searchResults = [...serp[fraseDocument.id].allArticles] as object[];
        let importedResults = [] as object[];

        dedupedItems &&
          dedupedItems.forEach((item) => {
            const index = searchResults.findIndex(
              (result) => result.url === item.url
            );
            if (index === -1) {
              importedResults.push({
                title: item.title,
                url: item.url,
                description: item.description,
                isValid: true,
                isCustomImport: true,
                index: 0,
                dateCreated: "",
              });
            }
          });

        searchResults = [...importedResults, ...searchResults];

        searchResults = searchResults.filter((result) => {
          return !Object.keys(tempBlacklist).includes(result.url);
        });

        const articles = validateArticles(dedupedItems, searchResults);
        const { wordCount, headerCount, linkCount, imageCount } =
          getArticleCounts(articles);
        const headings = getHeadings(articles);
        const serpQuestions = getQuestions(articles);
        const { domain_map, topics } = processBrief(
          dedupedItems,
          cluster_info,
          fraseDocument.query,
          fraseDocument.metadata?.lang_code
        );
        let scoredArticles = scoreSERP(topics, articles, fraseDocument);

        setSerp(fraseDocument.id, {
          ...serp[fraseDocument.id],
          results: searchResults,
          articles: scoredArticles || [],
          clusters: cluster_info,
          headings: headings,
          topics: topics,
          domainMap: domain_map,
          questions: {
            ...serp[fraseDocument.id].questions,
            serp: serpQuestions,
          },
          averageWordCount: wordCount,
          averageHeaderCount: headerCount,
          averageImageCount: imageCount,
          averageLinkCount: linkCount,
          urls:
            allArticles
              .map((article) => article.url)
              .filter((url) => !tempBlacklist[url]) || [],
          serpLoaded: true,
        });
        setIsLoadingSerp(false); // This will hide the loader
        handleNavigateBack();
      })
      .catch(() => {
        setIsLoadingSerp(false); // This will hide the loader
      });
  };

  const handleUpdateBlacklist = (url, isSelected) => {
    let newBlacklist = {};
    if (isSelected) {
      newBlacklist = {
        ...tempBlacklist,
      };

      delete newBlacklist[url];
    } else {
      newBlacklist = {
        ...tempBlacklist,
        [url]: true,
      };
    }

    setTempBlacklist(newBlacklist);
  };

  const handleSelectAll = () => {
    setTempBlacklist({});
  };

  const handleSelectNone = () => {
    const newBlacklist = {};

    allArticles.forEach((article) => {
      newBlacklist[article.url] = true;
    });

    setTempBlacklist(newBlacklist);
  };

  return (
    <ul className="flex flex-col pr-1 divide-y divide-zinc-900/5 dark:divide-zinc-800 last:border-b last:border-zinc-900/5 dark:last:border-zinc-800">
      <div className="flex justify-between items-center pt-0.5">
        <ControlButtons
          selectedArticlesLength={
            allArticles.length - Object.keys(tempBlacklist).length
          }
          validArticlesLength={allArticles.length}
          handleSaveBlacklist={handleSaveBlacklist}
          saveDisabled={
            _.isEqual(blacklist, tempBlacklist) && customUrls.length === 0
          }
          handleSelectAll={handleSelectAll}
          handleSelectNone={handleSelectNone}
          setCustomUrls={setCustomUrls}
        />
      </div>

      {allArticles.map((article, index) => {
        return (
          <CustomizeResultsCard
            blacklist={tempBlacklist}
            key={article.url}
            title={article.title}
            url={article.url}
            isCustomImport={article.isCustomImport || false}
            domainAuthority={article.domainAuthority}
            handleUpdateBlacklist={handleUpdateBlacklist}
          />
        );
      })}
    </ul>
  );
}
