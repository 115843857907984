import { Button, Card, Input } from "@/components/Elements";
import { LogoSpinner } from "@/components/Elements/Spinner/LogoSpinner";
import { cn } from "@/utils/style";
import { PlayCircleIcon } from "@heroicons/react/24/solid";
import axios from "axios";
import { motion } from "framer-motion";
import { debounce } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  TbBrandYoutubeFilled,
  TbExternalLink,
  TbHelpCircleFilled,
  TbSearch,
  TbX,
} from "react-icons/tb";
import { create } from "zustand";

export const useHelpCenterStore = create((set) => ({
  isHelpCenterOpen: false,
  openHelpCenter: () => set({ isHelpCenterOpen: true }),
  closeHelpCenter: () => set({ isHelpCenterOpen: false }),
  toggleHelpCenter: () =>
    set((state) => ({ isHelpCenterOpen: !state.isHelpCenterOpen })),
}));

function HelpCenterSearchResultCard({
  title,
  description,
  url,
  index,
}: {
  title: string;
  description: string;
  url: string;
  index: number;
}) {
  return (
    <div
      className="cursor-pointer"
      onClick={() => window.open(`https://support.frase.io${url}`, "_blank")}
    >
      <Card key={index} className="flex flex-col group">
        <div className="p-1">
          <div className="flex items-center space-x-2">
            <div className="font-medium text-sm group-hover:underline">
              {title}
            </div>
            <TbExternalLink className="w-3 h-3 text-xs text-zinc-500 invisible group-hover:visible" />
          </div>
          <p>{description}...</p>
        </div>
      </Card>
    </div>
  );
}

function HelpCenterResource({ title, description, url, icon, color }) {
  const IconComponent = icon;
  return (
    <div onClick={() => window.open(url, "_blank")}>
      <Card className="flex flex-col px-4 py-2 bg-white/90 dark:bg-zinc-900/50 shadow-sm ring-1 ring-zinc-900 dark:ring-zinc-50 dark:ring-opacity-5 ring-opacity-5 focus:outline-none cursor-pointer">
        <div className="font-medium text-sm flex items-center">
          <IconComponent
            className={cn("w-4 h-4 mr-2 text-zinc-500 dark:text-zinc-400")}
          />
          {title}
        </div>
        <p className="text-sm text-zinc-500 dark:text-zinc-400">
          {description}
        </p>
      </Card>
    </div>
  );
}

export default function HelpCenterPanel({ width }) {
  const { isHelpCenterOpen, closeHelpCenter } = useHelpCenterStore();
  const [searchResults, setSearchResults] = useState([]) as any;
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const panelRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (panelRef.current && !panelRef.current.contains(event.target)) {
        closeHelpCenter();
      }
    };

    // Attach the listener to the document
    document.addEventListener("mousedown", handleClickOutside);

    // Return a cleanup function to remove the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [closeHelpCenter]); // Only re-run if closeHelpCenter changes

  const panelVariants = {
    open: {
      x: 0,
      opacity: 1,
      zIndex: 999999,
      transition: {
        type: "easeOut",
        duration: 0.1,
      },
    },
    closed: {
      x: "100%",
      opacity: 0,
      zIndex: -50,
      transition: {
        type: "easeOut",
        duration: 0.1,
      },
    },
  };

  const debouncedSearch = useMemo(
    () =>
      debounce(async (searchTerm) => {
        setLoading(true);
        try {
          const response = await axios.get(
            `https://support.frase.io/_hcms/search?term=${encodeURIComponent(
              searchTerm
            )}&language=en&offset=0&type=KNOWLEDGE_ARTICLE&analytics=true&groupId=43294049451`
          );
          const results = response.data.results.map((article) => ({
            title: article.title.replace(/<[^>]+>/g, ""),
            url: article.url,
            description: article.description.replace(/<[^>]+>/g, ""),
          }));
          setSearchResults(results);
        } catch (error) {
          // Handle error
        }
        setLoading(false);
      }, 300),
    []
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        closeHelpCenter();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [closeHelpCenter]);

  useEffect(() => {
    if (searchTerm) {
      debouncedSearch(searchTerm);
    } else {
      setSearchResults([]);
    }
  }, [searchTerm, debouncedSearch]);

  const handleClearSearch = () => {
    setSearchResults([]);
    setSearchTerm("");
  };

  return (
    <>
      {isHelpCenterOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-20 z-[999999]"
          onClick={closeHelpCenter}
        ></div>
      )}

      <motion.div
        ref={panelRef}
        initial="closed"
        animate={isHelpCenterOpen ? "open" : "closed"}
        variants={panelVariants}
        onKeyDown={(event) => {
          if (event.key === "Escape") {
            closeHelpCenter();
          }
        }}
        className={cn(
          "fixed top-0 right-0 h-[calc(100vh-16px)] pb-20 px-2 pl-4 pt-4 space-y-4 overflow-y-scroll z-[99999] bg-white shadow-lg transform transition-transform duration-300 m-2",
          "flex flex-col rounded-md backdrop-blur-lg shadow-glowLg dark:shadow-xl ring-1 ring-zinc-900 dark:ring-1 dark:ring-zinc-50 dark:ring-opacity-5 ring-opacity-5 focus:outline-none dark:bg-zinc-800"
        )}
        style={{ width: 400 }}
      >
        <div className="flex flex-col space-y-2">
          <div className="text-base font-medium flex items-center">
            <span>Help Center</span>
            <Button
              buttonIcon={<TbX />}
              variant="buttonIcon"
              className="ml-auto"
              onClick={closeHelpCenter}
              aria-label="Close help center"
            />
          </div>
          <Input
            placeholder="Search help articles..."
            value={searchTerm}
            onChange={(value) => {
              setSearchTerm(value);
              if (value === "") {
                handleClearSearch();
              }
            }}
            startIcon={<TbSearch />}
          />

          {!loading && searchResults.length > 0 && (
            <div className="flex items-center justify-between">
              <p className="text-sm font-normal text-zinc-500">
                {searchResults.length} articles
              </p>
              <Button
                variant="text"
                className="self-end text-2xs font-normal"
                textClassName="text-zinc-500 hover:text-zinc-900 dark:text-zinc-400 dark:hover:text-zinc-100"
                onClick={handleClearSearch}
              >
                Clear results
              </Button>
            </div>
          )}
          {!loading &&
            searchResults.length > 0 &&
            searchResults.map((article, index) => (
              <HelpCenterSearchResultCard
                key={index}
                title={article.title}
                description={article.description}
                url={article.url}
                index={index}
              />
            ))}
        </div>
        {loading && (
          <div className="flex items-center justify-center w-full h-[25%]">
            <LogoSpinner variant="md" loadingText="Searching articles..." />
          </div>
        )}
        {!loading && searchResults.length === 0 && (
          <div className="space-y-4">
            <div className="font-medium text-sm text-zinc-600 dark:text-zinc-400">
              Resources
            </div>
            <HelpCenterResource
              title="Weekly Webinar"
              icon={PlayCircleIcon}
              description="Join us for our weekly webinar"
              url="https://www.frase.io/live-demo/"
            />
            <HelpCenterResource
              title="Help Center"
              icon={TbHelpCircleFilled}
              description="Browse our help center articles"
              url="https://support.frase.io"
            />
            <HelpCenterResource
              title="Youtube Channel"
              icon={TbBrandYoutubeFilled}
              description="Watch tutorials on our YouTube channel"
              url="https://www.youtube.com/@FraseAI"
            />
            <HelpCenterResource
              icon={TbHelpCircleFilled}
              title="Keyboard Shortcuts"
              description="Learn useful keyboard shortcuts"
              url="https://support.frase.io/keyboard-shortcuts"
            />
            <div className="space-y-4">
              <div className="font-medium text-sm text-zinc-600 dark:text-zinc-400">
                Support
              </div>

              <HelpCenterResource
                icon={TbHelpCircleFilled}
                title="Contact Support"
                description="Get in touch with our support team"
                url="https://support.frase.io/kb-tickets/new"
              />
            </div>
          </div>
        )}
      </motion.div>
    </>
  );
}
