import * as React from "react";
import { HelmetProvider } from "react-helmet-async";
import { QueryClientProvider } from "react-query";

import { BrowserRouter as Router } from "react-router-dom";

import { Toaster } from "@/components/Elements";
import { Notifications } from "@/components/Notifications/Notifications";
import { AuthProvider } from "@/lib/auth";
import { queryClient } from "@/lib/react-query";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Analytics } from "@vercel/analytics/react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
import { UpgradeDialogs } from "../features/upgrade/components/UpgradeDialog";
import "../lib/sentry";

dayjs.extend(updateLocale);
dayjs.extend(relativeTime);
dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s",
    s: "just now",
    ss: "just now",
    m: "1 min ago",
    mm: "%d mins ago",
    h: "1 hr ago",
    hh: "%d hrs ago",
    d: "1 day ago",
    dd: "%d days ago",
    M: "1 mo ago",
    MM: "%d mos ago",
    y: "1 year ago",
    yy: "%d yrs ago",
  },
});

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <React.StrictMode>
      <HelmetProvider>
        <QueryClientProvider client={queryClient}>
          <GoogleOAuthProvider
            clientId={import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_ID}
          >
            <AuthProvider>
              <Router>
                <Notifications />
                <UpgradeDialogs />
                {children}
              </Router>
            </AuthProvider>
          </GoogleOAuthProvider>
        </QueryClientProvider>
      </HelmetProvider>
      <Toaster />
      {process.env.VERCEL_PROJECT === "prod" && <Analytics />}
    </React.StrictMode>
  );
};
