import { Button, DialogModal, Textarea } from "@/components/Elements";
import { useEffect, useState } from "react";
import { axios } from "../../../lib/axios";
import { useNotificationStore } from "../../../stores/notifications";
import { useInviteUsersToOrg } from "../../teams/api/inviteUser";

export const InviteMembersButton = ({}) => {
  const [open, setOpen] = useState(false);
  const [emails, setEmails] = useState("");
  const [loading, setLoading] = useState(false);
  const { addNotification } = useNotificationStore();
  const { mutate: inviteUsers, isLoading: inviteUsersQuery } =
    useInviteUsersToOrg();

  useEffect(() => {
    if (open) {
      setEmails("");
      setLoading(false);
    }
  }, [open]);

  const checkUserExists = async (email) => {
    try {
      const response = await axios.post("/doesUserExistSystem", { email });
      return response; // Assuming the API returns { exists: boolean }
    } catch (error) {
      // Handle error (e.g., show notification)
      return false;
    }
  };

  const handleSubmit = async () => {
    const processedEmails = emails.split(",").map((email) => email.trim());
    let invalidEmails = [];
    let nonExistentEmails = [];
    let existingEmails = [];
    setLoading(true);

    for (const email of processedEmails) {
      // Simple email validation check
      if (!/^\S+@\S+\.\S+$/.test(email)) {
        invalidEmails.push(email);
      } else {
        // Check if the user exists
        const exists = await checkUserExists(email);
        if (!exists) {
          nonExistentEmails.push(email);
        } else {
          existingEmails.push(email);
        }
      }
    }

    if (invalidEmails.length > 0) {
      addNotification({
        title: "Invalid Email",
        message: `The following emails are invalid: ${invalidEmails.join(
          ", "
        )}.`,
        type: "error",
      });
      setLoading(false);
      return;
    }

    if (nonExistentEmails.length > 0) {
      const payload = {
        userEmails: nonExistentEmails.map((email) => ({ email, role: 2 })),
      };

      // Use the `inviteUsers` mutation to send invites
      inviteUsers(payload, {
        onSuccess: () => {
          addNotification({
            title: `${nonExistentEmails.length} invites sent`,
            message: `Invites have been sent to the following emails: ${nonExistentEmails.join(
              ", "
            )}.`,
            type: "success",
          });
          setOpen(false);
        },
        onError: (error) => {
          // Handle the error case
          addNotification({
            title: "Error",
            message: "There was an error sending the invites.",
            type: "error",
          });
        },
      });
    } else {
      addNotification({
        title: "Users already exist",
        message: `The users with the following emails already exist: ${processedEmails.join(
          ", "
        )}.`,
        type: "info",
      });
    }
    setLoading(false);
  };

  return (
    <DialogModal
      open={open}
      setOpen={setOpen}
      trigger={
        <Button
          variant="outlineBlur"
          className="flex-shrink-0 h-8"
          loading={inviteUsersQuery || loading}
        >
          Invite members
        </Button>
      }
      className="gap-0"
      variant="fitContent"
      title="Invite members by email"
      footer={
        <div className="mt-4 text-center flex-col">
          <Button
            type="submit"
            variant="primaryBlur"
            disabled={emails.length === 0}
            isLoading={inviteUsersQuery || loading}
            onClick={handleSubmit}
          >
            Send invites
          </Button>
        </div>
      }
    >
      <Textarea
        value={emails}
        onChange={(e) => setEmails(e.target.value)}
        placeholder="user1@example.com, user2@example.com..."
        className="h-24 resize-none"
      />
    </DialogModal>
  );
};
