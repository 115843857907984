import { useNavigate } from "react-router-dom";

import { HeroPattern, Spinner } from "@/components/Elements";
import { Head } from "@/components/Head";
import { useAuth } from "@/lib/auth";
import { useEffect } from "react";

export const Landing = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      navigate("/app");
    } else {
      navigate("/login");
    }
  }, [user, navigate]);

  return (
    <>
      <Head description="Welcome to Frase" />
      <div className="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <HeroPattern />
        <div className="w-screen h-screen flex justify-center items-center">
          <Spinner size="md" />
        </div>
      </div>
    </>
  );
};
