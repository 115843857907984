export function getPlanNickname(name: string): string {
  if (name.includes("solo")) {
    return "Solo";
  } else if (name.includes("team")) {
    return "Team";
  } else if (name.includes("basic")) {
    return "Basic";
  } else if (name.includes("answer")) {
    return "Growth";
  } else {
    return "Free";
  }
}
