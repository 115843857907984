import { motion } from "framer-motion";
import storage from "../../../utils/storage";
import { Layout } from "../components/Layout";
import { LoginForm } from "../components/LoginForm";

export const Login = () => {
  const lastVisitedPath = storage.getLastVisitedPath();
  const isDocumentRoute = lastVisitedPath?.startsWith("/app/documents/");
  const isExtensionRoute = lastVisitedPath?.startsWith("/app/extension/");

  const bannerVariants = {
    hidden: { y: "-100%", opacity: 0 },
    visible: { y: 0, opacity: 1, transition: { duration: 0.5 } },
  };

  return (
    <>
      {isDocumentRoute && (
        <motion.div
          initial="hidden"
          animate="visible"
          variants={bannerVariants}
          className="flex items-center justify-center w-full h-full p-2"
        >
          <div className="flex h-12 w-full bg-emerald-600 items-center justify-center rounded-md shadow-lg bg-glass-gradient">
            <p className="text-center font-medium text-white">
              Please log in or register to access this document.
            </p>
          </div>
        </motion.div>
      )}
      {isExtensionRoute && (
        <motion.div
          initial="hidden"
          animate="visible"
          variants={bannerVariants}
          className="flex items-center justify-center w-full h-full p-2"
        >
          <div className="flex h-12 w-full bg-emerald-600 items-center justify-center rounded-md shadow-lg bg-glass-gradient">
            <p className="text-center font-medium text-white">
              Please log in or register to activate the extension.
            </p>
          </div>
        </motion.div>
      )}
      <Layout title="Log in to Frase">
        <LoginForm />
      </Layout>
    </>
  );
};
