import React from "react";
import { TbArrowRight } from "react-icons/tb";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../components/Elements";
import { LogoSpinner } from "../../../components/Elements/Spinner/LogoSpinner";
import { useUser } from "../../auth";
import { Layout } from "../../auth/components/Layout";
import { useVerifyEmail } from "../api/verifyEmail";

export const ConfirmVerification = () => {
  const { data: user, isLoading: isLoadingUser } = useUser();
  const { token } = useParams();
  const {
    mutate: verifyEmail,
    isLoading,
    isError,
    isSuccess,
  } = useVerifyEmail();
  const navigate = useNavigate();

  const handleReturnHome = () => {
    if (user?.onBoarded) {
      navigate("/app", { replace: true });
    } else {
      navigate("/app/onboarding", { replace: true });
    }
  };

  React.useEffect(() => {
    if (token) {
      verifyEmail(token);
    }
  }, [token, verifyEmail]);

  if (isLoadingUser) {
    return (
      <div className="flex items-center justify-center h-screen">
        <LogoSpinner variant="md" loadingText="" />
      </div>
    );
  }

  return (
    <Layout title={""}>
      <div className="flex flex-col items-center justify-center space-y-8">
        {isLoading && (
          <p className="text-center dark:text-white">Verifying...</p>
        )}
        {isError && (
          <div>
            <p className="text-center dark:text-white">
              Verification failed. Please try again.
            </p>
          </div>
        )}
        {isSuccess && (
          <>
            <p className="text-center dark:text-white">
              Email successfully verified!
            </p>
            <Button
              isLoading={isLoading}
              onClick={handleReturnHome}
              className="w-full"
              endIcon={<TbArrowRight className="w-4 h-4" />}
            >
              Go to Frase
            </Button>
          </>
        )}
      </div>
    </Layout>
  );
};
