"use client";

import * as DialogPrimitive from "@radix-ui/react-dialog";
import * as React from "react";

import { cn } from "@/utils/style";

const Dialog = ({ modal, ...props }: DialogPrimitive.DialogProps) => (
  <DialogPrimitive.Root modal={modal} {...props}></DialogPrimitive.Root>
);

const DialogTrigger = DialogPrimitive.Trigger;

const DialogPortal = ({
  className,
  children,
  style,
  ...props
}: DialogPrimitive.DialogPortalProps) => (
  <DialogPrimitive.Portal className={cn(className)} {...props}>
    <div
      className={cn(
        "fixed inset-0 z-[51] flex items-start justify-center sm:items-center focus:none",
        className
      )}
      style={style}
    >
      {children}
    </div>
  </DialogPrimitive.Portal>
);
DialogPortal.displayName = DialogPrimitive.Portal.displayName;

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, children, ...props }, ref) => (
  <DialogPrimitive.Overlay
    className={cn(
      "fixed inset-0 z-50 bg-white/80 dark:bg-zinc-900/70",
      className
    )}
    {...props}
    ref={ref}
  />
));
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

const contentVariants = {
  fitContent:
    "fixed z-[51] grid w-full gap-4 p-1 rounded-b-lg border dark:border-zinc-700 bg-white dark:bg-zinc-800 shadow-2xl sm:max-w-lg sm:rounded-lg sm:zoom-in-90",
  wizard: "w-full h-full",
  fullScreen:
    "overflow-x-scroll flex flex-col z-[51] rounded-md pb-0 bg-white ring-1 ring-zinc-900/7.5 dark:bg-zinc-900 dark:ring-zinc-800 shadow-xl",
  templates:
    "z-[51] bg-white dark:bg-zinc-800 ring-1 ring-zinc-900/7.5 dark:ring-zinc-800 rounded-md shadow-xl w-full max-w-[90vw] mx-auto",
};

const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>
>(
  (
    {
      className,
      containerClassName,
      children,
      onClose,
      style,
      variant = "fitContent",
      closeButtonClassName,
      preventCloseOnEscape = false,
      ...props
    },
    ref
  ) => (
    <DialogPortal className={containerClassName} style={style}>
      <DialogOverlay />
      <DialogPrimitive.Content
        ref={ref}
        className={cn(
          contentVariants[variant],
          className,
          "focus:[&:not(:focus-visible)]:outline-none focus:outline-none focus:ring-zinc-900/7.5 aria-selected:ring-transparent aria-selected:outline-none"
        )}
        {...props}
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            if (preventCloseOnEscape) {
              e.stopPropagation();
            }
          }
        }}
      >
        {children}
      </DialogPrimitive.Content>
    </DialogPortal>
  )
);
DialogContent.displayName = DialogPrimitive.Content.displayName;

const DialogHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "flex text-center sm:text-left p-4 font-medium text-xs text-zinc-900 dark:text-white",
      className
    )}
    {...props}
  />
);
DialogHeader.displayName = "DialogHeader";

const DialogFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2",
      className
    )}
    {...props}
  />
);
DialogFooter.displayName = "DialogFooter";

const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cn(
      "text-lg font-semibold text-zinc-900",
      "dark:text-white",
      className
    )}
    {...props}
  />
));
DialogTitle.displayName = DialogPrimitive.Title.displayName;

const DialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => {
  const [atTop, setAtTop] = React.useState(true);

  const handleScroll = (e) => {
    const atTop = e.target.scrollTop < 10;

    setAtTop(atTop);
  };

  return (
    <DialogPrimitive.Description
      onScroll={handleScroll}
      ref={ref}
      className={cn(
        "overflow-y-scroll h-full text-sm text-zinc-500 w-full",
        "dark:text-white",
        !atTop && "border-t dark:border-t-zinc-800",
        className
      )}
      {...props}
    />
  );
});
DialogDescription.displayName = DialogPrimitive.Description.displayName;

export {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
};
