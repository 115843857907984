import { ContentLayout } from "@/components/Layout";
import { SubmitTicketButton } from "@/features/help/components/SubmitTicketDialog";
import { useGetUpcomingInvoice } from "@/features/subscription/api/getUpcomingInvoices";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { Button, InputLabel, Tag } from "../../../components/Elements";
import { Skeleton } from "../../../components/Elements/Skeleton";
import { useSubscription } from "../../auth/api/getSubscription";
import { getPlanNickname } from "../../subscription/utils/getPlanNickname";
import { tiers } from "../../subscription/utils/tiers";
import { ConfirmCancelAddOnDialog } from "../components/ConfirmCancelAddOnDialog";
import { ConfirmCancelSubscriptionDialog } from "../components/ConfirmCancelSubscriptionDialog";
import { ConfirmPlanChangeDialog } from "../components/ConfirmPlanChangeDialog";

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_KEY as string);

export const Subscription = () => {
  const navigate = useNavigate();

  const { data: subscriptionData, isLoading: isLoadingSubscription } =
    useSubscription({});

  const shouldRenderUpcomingInvoice = subscriptionData?.status === "active";

  const { data: upcomingInvoice, isLoading: isLoadingUpcomingInvoice } =
    useGetUpcomingInvoice({
      config: {
        enabled: shouldRenderUpcomingInvoice,
      },
    });

  const isLoading = isLoadingSubscription || isLoadingUpcomingInvoice;

  const isSubscriptionActive = subscriptionData?.status === "active";
  const isSubscriptionExpired = subscriptionData?.status === "canceled";
  const isAppsumoUser = subscriptionData?.appsumo || false;

  const subscriptionEndDate = subscriptionData?.subscription_end_date
    ? dayjs(subscriptionData.subscription_end_date).format("MMM DD, YYYY")
    : null;

  const planNickname = getPlanNickname(subscriptionData?.plan || "Free");
  const pricingPeriod =
    subscriptionData?.billing_cycle === "year" ? "yearly" : "monthly";
  const pricingPeriodDisplay =
    pricingPeriod.charAt(0).toUpperCase() + pricingPeriod.slice(1);
  const currentTier = tiers.find((t) => t.name === planNickname);

  const subscriptionDetails = {
    plan: planNickname,
    pricingPeriod: pricingPeriod,
    planName: subscriptionData?.plan || "Free Plan",
    proAddon: subscriptionData?.add_on ? "Active" : "Inactive",
    isAppsumoActive: subscriptionData?.appsumo || false,
    price:
      tiers.find((t) => t.name === planNickname)?.[pricingPeriod]?.price || 0,
    hasAddOn: !!subscriptionData?.add_on,
  };

  return (
    <ContentLayout>
      <div className="flex flex-col items-start h-screen pt-5 px-8 w-full space-y-4 overflow-y-scroll">
        <div>
          <h2 className="text-xl dark:text-white font-medium">Subscription</h2>
          <p className="text-sm text-zinc-500 dark:text-zinc-400 mt-2 min-w-[550px] mb-4">
            Manage your subscription details.
          </p>

          {/* Plan Section */}
          <section className="space-y-4">
            <h3 className="text-md dark:text-white font-medium pt-4 border-t dark:border-t-zinc-800 w-full">
              Plan
            </h3>
            <div>
              <InputLabel label="Current plan" />
              <div className="text-sm dark:text-white">
                {isLoading ? (
                  <Skeleton className="h-6 w-full" />
                ) : isSubscriptionActive ? (
                  <div className="flex items-center justify-between">
                    <p className="text-sm dark:text-white">
                      {subscriptionDetails.plan}
                    </p>
                    {!isAppsumoUser && (
                      <Button
                        variant="outlineBlur"
                        onClick={() => {
                          navigate("/app/settings/plans");
                        }}
                        size="xs"
                      >
                        Change plan
                      </Button>
                    )}
                  </div>
                ) : isSubscriptionExpired ? (
                  <span className="flex items-center flex-wrap">
                    Your{" "}
                    <span className="mx-1 font-medium">
                      {subscriptionDetails.plan} Plan
                    </span>{" "}
                    expired on
                    {subscriptionEndDate}.
                  </span>
                ) : (
                  <span className="flex items-center flex-wrap">
                    Your{" "}
                    <span className="mx-1 font-medium">
                      {subscriptionDetails.plan} Plan{" "}
                      {subscriptionDetails.proAddon === "Active" &&
                        subscriptionData?.status === "active_canceled" &&
                        "and Pro add-on"}{" "}
                    </span>{" "}
                    has been canceled and will remain active until{" "}
                    {subscriptionEndDate}.
                  </span>
                )}
              </div>
            </div>

            {isSubscriptionActive && (
              <div>
                <InputLabel label="Pro add-on" />
                <div className="flex items-center justify-between">
                  {isLoading ? (
                    <Skeleton className="h-6 w-32" />
                  ) : (
                    <>
                      {subscriptionDetails.proAddon === "Active" ? (
                        <>
                          <Tag color="emerald" className="w-fit">
                            {subscriptionDetails.proAddon}
                          </Tag>
                          <ConfirmCancelAddOnDialog />
                        </>
                      ) : (
                        <div className="flex items-center justify-between w-full">
                          <p className="text-sm dark:text-white">
                            {subscriptionDetails.proAddon}
                          </p>
                          <Elements stripe={stripePromise}>
                            <ConfirmPlanChangeDialog
                              triggerButton={
                                <Button
                                  variant={"outlineBlur"}
                                  className="w-fit"
                                  size="xs"
                                >
                                  Upgrade to Pro add-on
                                </Button>
                              }
                              title={"Upgrade to Pro add-on"}
                              upgradeProAddOn={true}
                              actionType="upgrade"
                            />
                          </Elements>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}

            <div>
              <InputLabel label="Billing cycle" />
              {isLoading ? (
                <Skeleton className="h-6 w-full" />
              ) : (
                <div className="flex items-center justify-between">
                  <p className="text-sm dark:text-white">
                    {pricingPeriodDisplay}
                  </p>
                  {isSubscriptionActive && !isAppsumoUser && (
                    <Elements stripe={stripePromise}>
                      <ConfirmPlanChangeDialog
                        planDetails={{
                          name: subscriptionDetails.plan,
                          stripeName:
                            pricingPeriod === "monthly"
                              ? currentTier?.yearly.stripeName
                              : currentTier?.monthly.stripeName,
                          title: subscriptionDetails.plan + " Plan",
                          price:
                            pricingPeriod === "monthly"
                              ? currentTier?.yearly.price
                              : currentTier?.monthly.price,
                        }}
                        hasAddOn={subscriptionDetails.hasAddOn}
                        tiers={tiers}
                        pricingPeriod={subscriptionDetails.pricingPeriod}
                        actionType="change"
                        className="w-fit"
                      />
                    </Elements>
                  )}
                </div>
              )}
            </div>

            {isLoading ? (
              <Skeleton className="h-6 w-32" />
            ) : subscriptionDetails.isAppsumoActive ? (
              <div>
                <InputLabel label="Appsumo LTD" />
                <div className="flex items-center justify-between">
                  <Tag color="emerald" className="w-fit mt-1">
                    Active
                  </Tag>
                </div>
                {isAppsumoUser && (
                  <div className="text-xs text-zinc-500 mt-2">
                    <span>Need to change your LTD plan?</span>
                    <SubmitTicketButton
                      triggerButton={
                        <Button
                          variant="link"
                          className="ml-2 hover:underline"
                          textClassName="text-xs"
                        >
                          Contact support
                        </Button>
                      }
                    />
                  </div>
                )}
              </div>
            ) : null}

            {isLoading ? (
              <Skeleton className="h-6 w-full" />
            ) : subscriptionEndDate ? (
              <div>
                <InputLabel
                  label={
                    subscriptionData?.status === "canceled" ||
                    subscriptionData?.status === "active_canceled"
                      ? "Subscription End Date"
                      : "Subscription Renewal Date"
                  }
                />
                <div className="flex items-center justify-between">
                  <p className="text-sm dark:text-white">
                    {subscriptionEndDate}
                  </p>
                  {(subscriptionData?.status === "canceled" ||
                    subscriptionData?.status === "active_canceled") && (
                    <Elements stripe={stripePromise}>
                      <ConfirmPlanChangeDialog
                        className="w-fit"
                        planDetails={{
                          name: subscriptionDetails.plan,
                          stripeName: subscriptionDetails.planName,
                          title: subscriptionDetails.plan,
                          price: subscriptionDetails.price,
                        }}
                        hasAddOn={subscriptionDetails.hasAddOn}
                        tiers={tiers}
                        pricingPeriod={subscriptionDetails.pricingPeriod}
                        shouldResubscribe={true}
                        status={subscriptionData?.status}
                        actionType="resubscribe"
                      />
                    </Elements>
                  )}
                </div>
              </div>
            ) : null}

            {isLoading ? (
              <Skeleton className="h-6 w-full" />
            ) : shouldRenderUpcomingInvoice && upcomingInvoice ? (
              <div>
                <InputLabel label="Next invoice" />
                <div className="flex items-center justify-between">
                  <p className="text-sm dark:text-white">
                    {dayjs(upcomingInvoice.date * 1000).format("MMM DD, YYYY")}{" "}
                    for{" "}
                    {(upcomingInvoice.amountDue / 100).toLocaleString("en-US", {
                      style: "currency",
                      currency: upcomingInvoice.currency,
                    })}
                  </p>
                  <Button
                    variant="outlineBlur"
                    onClick={() => navigate("/app/settings/billing")}
                    size="xs"
                  >
                    View billing
                  </Button>
                </div>
              </div>
            ) : null}
          </section>

          {isLoading ? (
            <Skeleton className="h-6 w-full" />
          ) : isSubscriptionActive && !isAppsumoUser ? (
            <div className="w-full border-t dark:border-t-zinc-800 py-4 mt-4">
              <ConfirmCancelSubscriptionDialog />
            </div>
          ) : null}
        </div>
      </div>
    </ContentLayout>
  );
};
