import { axios } from "@/lib/axios";
import { useQuery } from "react-query";

export type Subscription = {
  subscription_start_date?: number;
  service_plan?: boolean;
  add_on?: boolean;
  subscription_length?: number;
  plan?: string;
  appsumo?: boolean;
  status?: "active_canceled" | "canceled" | "inactive" | "active";
  billing_cycle?: string;
};

export const getSubscription = async (): Promise<Subscription> => {
  const data = await axios.post("/stripe/subscription_info");
  return data;
};

export const updateSubscriptionFrase = async (
  subscriptionInfo?: Subscription
) => {
  // Assuming the Java endpoint expects the subscription info in the request body
  const data = await axios.post(
    "/stripe/update_subscription_frase",
    subscriptionInfo
  );
  return data;
};

export const useSubscriptionUpdate = () => {
  return useQuery("subscriptionUpdate", () => updateSubscriptionFrase({}));
};

export const useSubscription = ({ config }: { config?: any }) => {
  const query = useQuery("subscription", getSubscription, config);

  if (query.data?.plan?.includes("answers")) {
    window.location.href = "https://app.frase.io";
  }

  return query;
};
