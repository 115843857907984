"use client";

import * as PopoverPrimitive from "@radix-ui/react-popover";
import * as React from "react";
import { cn } from "../../../utils/style";

const Popover = PopoverPrimitive.Root;

const PopoverTrigger = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Trigger>
>(({ className, ...props }, ref) => (
  <PopoverPrimitive.Trigger
    ref={ref}
    className={cn(
      "rounded-md hover:bg-zinc-900/5 dark:hover:bg-white/5 dark:text-white",
      className
    )}
    {...props}
  />
));
PopoverTrigger.displayName = PopoverPrimitive.Trigger.displayName;

const PopoverContent = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>
>(({ className, align = "center", sideOffset = 8, ...props }, ref) => (
  <PopoverPrimitive.Portal>
    <PopoverPrimitive.Content
      ref={ref}
      align={align}
      sideOffset={sideOffset}
      className={cn(
        "z-[999] origin-top-right divide-y divide-zinc-100 dark:divide-zinc-600 rounded-md backdrop-blur-lg bg-white/90 dark:bg-zinc-900/50 shadow-lg ring-1 ring-zinc-900 dark:ring-1 dark:ring-zinc-50 dark:ring-opacity-5 ring-opacity-5 focus:outline-none",
        className
      )}
      {...props}
    />
  </PopoverPrimitive.Portal>
));
PopoverContent.displayName = PopoverPrimitive.Content.displayName;

const PopoverMenuGroup = ({ children, ...props }) => (
  <div {...props} className="p-1">
    {children}
  </div>
);

const PopoverMenuItem = React.forwardRef<
  HTMLButtonElement,
  React.ComponentPropsWithoutRef<"button"> & { children: React.ReactNode } // Add children prop type here
>(({ children, onClick, className, ...props }, ref) => (
  <button
    ref={ref}
    onClick={onClick}
    className={cn(
      "flex w-full items-center rounded-md px-2 py-1 text-xs font-normal text-left hover:bg-zinc-100 dark:hover:bg-zinc-700 focus:outline-none dark:text-white",
      className
    )}
    {...props}
  >
    {children}
  </button>
));
PopoverMenuItem.displayName = "PopoverMenuItem";

export {
  Popover,
  PopoverContent,
  PopoverMenuGroup,
  PopoverMenuItem,
  PopoverTrigger,
};
