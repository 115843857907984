import PlaygroundNodes from "@/components/AdvancedEditor/nodes/TableCellNodes";
import { ExtendedTextNode } from "@/components/AdvancedEditor/plugins/ExtendedTextNodePlugin/ExtendedTextNodePlugin";
import { PLAYGROUND_TRANSFORMERS } from "@/components/AdvancedEditor/plugins/MarkdownTransformers";
import PlaygroundEditorTheme from "@/components/AdvancedEditor/themes/PlaygroundEditorTheme";
import {
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Spinner,
} from "@/components/Elements";
import { LogoSpinner } from "@/components/Elements/Spinner/LogoSpinner";
import useBriefFactory from "@/features/documents/utils/brief";
import { Template } from "@/features/templates";
import { PrepoulateContentPreviewPlugin } from "@/features/templates/components/ImportTemplateButton";
import { useDocumentStore } from "@/stores/document";
import { useEditorStore } from "@/stores/editor";
import { useNotificationStore } from "@/stores/notifications";
import { useSerpStore } from "@/stores/serp";
import { cn } from "@/utils/style";
import { $generateNodesFromDOM } from "@lexical/html";
import { $convertFromMarkdownString } from "@lexical/markdown";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";
import * as Sentry from "@sentry/react";
import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  $insertNodes,
  TextNode,
} from "lexical";
import { useMemo, useRef, useState } from "react";
import { TbArrowLeft, TbCheck, TbX } from "react-icons/tb";
import TurndownService from "turndown";

const briefSections = [
  {
    description: "High level goals and guidelines.",
    format: "list",
    name: "Guidelines",
    selected: true,
    explainer:
      "Guidelines provide a high-level overview of the goals and best practices for creating topically relevant content that aligns with Semantic SEO principles.",
  },
  {
    description: "Titles generated with AI based on search results.",
    format: "list",
    name: "Titles",
    selected: true,
    explainer:
      "Titles are generated to reflect the overall topic and user intent, ensuring they are optimized for both keywords and semantic relevance.",
  },
  {
    description: "Related questions asked on Google.",
    format: "list",
    name: "People Also Ask",
    selected: true,
    explainer:
      "People Also Ask questions highlight common queries related to the topic, helping to create content that answers these questions and improves search visibility.",
  },
  {
    description: "Search results with titles & descriptions.",
    format: "list",
    name: "SERP",
    selected: true,
    explainer:
      "SERP data includes titles and descriptions from search results, providing insights into how top-ranking pages cover the topic.",
  },
  {
    description: "Header structure generated with AI based on search results.",
    format: "list",
    name: "Outline",
    selected: true,
    explainer:
      "Outlines help in structuring content to cover all subtopics comprehensively, ensuring the content is topically relevant and semantically rich.",
  },
  {
    description: "Top 20 topics and clusters across search results.",
    explainer:
      "Top 20 semantic topics and topic clusters across search results. Google looks at these topic mentions as an important factor when ranking content.",
    format: "list",
    name: "Topics",
    selected: true,
  },
  {
    description: "Relevant questions across search results.",
    format: "list",
    name: "Questions",
    selected: false,
    explainer:
      "Questions from search results provide additional insights into user intent and common queries, aiding in creating comprehensive content.",
  },
  {
    description: "Factual sentences across search results.",
    format: "list",
    name: "Statistics",
    selected: false,
    explainer:
      "Statistics and factual sentences add credibility and depth to the content, supporting claims with data and enhancing the overall quality.",
  },
  {
    description: "Pages that search results link to.",
    format: "list",
    name: "Hyperlinks",
    selected: false,
    explainer:
      "Hyperlinks show the pages that top search results link to, offering insights into authoritative sources and potential backlink opportunities.",
  },
];

function BriefPreviewEditor({
  html,
  title,
  setOpen,
  isLoading,
}: {
  html: string;
  title?: string;
  selectedTemplate: Template | null;
  setOpen: (open: boolean) => void;
  isLoading?: boolean;
}) {
  const section = briefSections.find((s) => s.name === title);
  return (
    <div className={cn("space-y-4 flex flex-col flex-1 pt-4 overflow-hidden")}>
      <div className="flex justify-between">
        <div className="flex flex-col space-y-3.5">
          <p className="w-full text-base font-semibold text-zinc-800 dark:text-zinc-200">
            {title}
          </p>

          {section?.explainer && (
            <p className="text-xs text-zinc-500 ">{section.explainer}</p>
          )}
        </div>
        <DialogCloseButton close={() => setOpen(false)} />
      </div>

      <div className="flex flex-col space-y-2 flex-1 overflow-hidden">
        <div
          className="border-zinc-100 dark:border-zinc-800 border rounded-md p-4 flex-1 overflow-y-auto"
          key={title}
        >
          {isLoading ? (
            <div className="relative flex items-center justify-center w-full h-[25%] mt-8">
              <LogoSpinner
                variant="md"
                loadingText="Generating content brief..."
              ></LogoSpinner>
            </div>
          ) : (
            <div className="flex flex-col">
              <LexicalComposer
                initialConfig={{
                  editable: false,
                  namespace: "Preview",
                  onError: (error: Error) => {
                    Sentry.captureException(error);
                  },
                  nodes: [
                    ...PlaygroundNodes,
                    ExtendedTextNode,
                    {
                      replace: TextNode,
                      with: (node: TextNode) =>
                        new ExtendedTextNode(node.__text),
                    },
                  ],
                  theme: PlaygroundEditorTheme,
                }}
              >
                <PlainTextPlugin
                  contentEditable={
                    <ContentEditable className="h-full w-full max-w-[750px] flex-1 mx-auto min-w-0 mb-40 pt-8 px-8" />
                  }
                  placeholder={null}
                  ErrorBoundary={LexicalErrorBoundary}
                />

                <PrepoulateContentPreviewPlugin html={html} />
              </LexicalComposer>
              <div className="h-40"></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function SectionCard({
  selectedSection,
  setSelectedSection,
  generated,
  loadingSections,
  handleSectionToggle,
  handleSectionRemove,
  section,
  index,
  isPreviewing,
}: {
  selectedSection: string | null;
  setSelectedSection: (name: string) => void;
  generated: boolean;
  loadingSections: boolean[];
  handleSectionToggle: (name: string) => void;
  handleSectionRemove: (name: string) => void;
  section: {
    description: string;
    format: string;
    name: string;
    selected: boolean;
  };
  index: number;
  isPreviewing: boolean;
}) {
  return (
    <Card
      className={cn(
        "flex p-2 text-2xs dark:text-white bg-white dark:bg-zinc-900 rounded-md ring-1 ring-inset ring-zinc-900/7.5 group-hover:ring-zinc-900/10 dark:ring-white/10 dark:group-hover:ring-white/20 hover:bg-zinc-50 dark:hover:bg-zinc-800 hover:shadow-zinc-900/5 dark:bg-white/2.5 dark:hover:shadow-zinc-50/5 cursor-pointer group",
        selectedSection === section.name &&
          isPreviewing &&
          "bg-zinc-100 dark:bg-zinc-800"
      )}
      onClick={() => {
        if (isPreviewing) {
          setSelectedSection(section.name);
        } else {
          handleSectionToggle(section.name);
        }
      }}
    >
      {loadingSections[index] ? (
        <Spinner className="w-4 h-4 m-2 self-start" />
      ) : !isPreviewing ? (
        <Checkbox
          checked={section.selected}
          onChange={() => {
            handleSectionToggle(section.name);
          }}
          className="m-2 self-start"
        />
      ) : (
        <TbCheck className="m-2 self-start text-emerald-600 stroke-[3.5px]" />
      )}
      <div className="ml-2 mt-0.5 flex-1">
        <p className="font-medium text-xs">{section.name}</p>
        <span className="text-xs opacity-70">{section.description}</span>
      </div>
      {isPreviewing && (
        <Button
          variant="buttonIcon"
          className="invisible group-hover:visible"
          size="xs"
          onClick={(e) => {
            e.stopPropagation();
            handleSectionRemove(section.name);
          }}
          buttonIcon={<TbX />}
          tooltipContent="Remove section"
        ></Button>
      )}
    </Card>
  );
}

export const GenerateContentBriefButton = ({
  open,
  setOpen,
  trigger,
  setShowPlaceholders,
  setShowLinePlaceholder,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  trigger?: React.ReactNode;
  setShowPlaceholders: (show: boolean) => void;
  setShowLinePlaceholder: (show: boolean) => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isPreviewing, setIsPreviewing] = useState(false);
  const [sampleTexts, setSampleTexts] = useState<{ [key: string]: string }>({});
  const [selectedSection, setSelectedSection] = useState<string | null>(null);
  const [generated, setGenerated] = useState(false);
  const [sections, setSections] = useState(briefSections);
  const [isInsertingToEditor, setIsInsertingToEditor] = useState(false);
  const [loadingSections, setLoadingSections] = useState<boolean[]>(
    Array(briefSections.length).fill(false)
  );
  const { editor } = useEditorStore();
  const lexicalEditorInstance = editor.instance;
  const { addNotification } = useNotificationStore();
  const { processBrief, getBriefSection } = useBriefFactory();
  const { serp } = useSerpStore();
  const { document: fraseDocument } = useDocumentStore();
  const [activeData, setActiveData] = useState(null);
  const scrollRef = useRef<HTMLDivElement>(null);

  const handleSectionRemove = (name: string) => {
    setSections((prevSections) => {
      const updatedSections = prevSections.map((section) =>
        section.name === name ? { ...section, selected: false } : section
      );
      if (updatedSections.every((section) => !section.selected)) {
        setIsPreviewing(false);
      }
      return updatedSections;
    });
    setSampleTexts((prev) => {
      const newSampleTexts = { ...prev };
      delete newSampleTexts[name];
      return newSampleTexts;
    });
    if (name === selectedSection) {
      const nextSection = sections.find(
        (section) => section.name !== name && sampleTexts[section.name]
      );
      setSelectedSection(nextSection ? nextSection.name : null);
    }
  };

  const handleSectionToggle = (name: string) => {
    setSections((prevSections) =>
      prevSections.map((section) =>
        section.name === name
          ? { ...section, selected: !section.selected }
          : section
      )
    );
  };

  const handleGenerate = async () => {
    setIsLoading(true);
    setIsPreviewing(true);
    setSelectedSection(
      sections.find((section) => section.selected)?.name || null
    );

    setLoadingSections(Array(briefSections.length).fill(true));
    scrollRef.current?.scrollTo(0, 0);

    const data = await processBrief(
      serp[fraseDocument.id]?.articles || [],
      serp[fraseDocument.id]?.clusters || [],
      fraseDocument.query,
      fraseDocument.metadata.lang_code,
      serp[fraseDocument.id]?.peopleAlsoAsk || [],
      fraseDocument.hash
    );

    const updateSampleText = (section, html) => {
      setSampleTexts((prev) => ({ ...prev, [section]: html }));
    };

    const selectedSections = sections.filter((section) => section.selected);

    for (let index = 0; index < selectedSections.length; index++) {
      const section = selectedSections[index];
      setLoadingSections((prev) => {
        const newLoadingSections = [...prev];
        newLoadingSections[index] = true;
        return newLoadingSections;
      });

      await getBriefSection(
        section.name,
        section.format,
        data,
        updateSampleText,
        fraseDocument,
        serp[fraseDocument.id] || {}
      );

      setLoadingSections((prev) => {
        const newLoadingSections = [...prev];
        newLoadingSections[index] = false;
        return newLoadingSections;
      });
    }

    setIsLoading(false);
    setLoadingSections(Array(briefSections.length).fill(false));
  };

  const handleBack = () => {
    setIsPreviewing(false);
    setGenerated(false);
    setIsLoading(false);
    setLoadingSections(Array(briefSections.length).fill(false));
  };

  const handleSelectAllToggle = () => {
    const allSelected = sections.every((section) => section.selected);
    setSections((prevSections) =>
      prevSections.map((section) => ({
        ...section,
        selected: !allSelected,
      }))
    );
  };

  const handleInsertToEditor = async () => {
    setIsInsertingToEditor(true);
    setShowPlaceholders(false);

    lexicalEditorInstance?.update(() => {
      const root = $getRoot();
      root.selectEnd();

      sections.forEach((section) => {
        if (section.selected) {
          const dom = new DOMParser().parseFromString(
            sampleTexts[section.name],
            "text/html"
          );
          let nodes = $generateNodesFromDOM(lexicalEditorInstance, dom);

          if (nodes.length === 0) {
            nodes.push($createParagraphNode());
          }

          try {
            $insertNodes(nodes);
            $insertNodes([$createParagraphNode(), $createParagraphNode()]);
          } catch (error) {
            const markdown = new TurndownService().turndown(
              sampleTexts[section.name]
            );
            const paragraphNode = $createParagraphNode();
            const textNode = $createTextNode(markdown);
            paragraphNode.append(textNode);
            $insertNodes([paragraphNode]);

            $convertFromMarkdownString(
              root.getTextContent(),
              PLAYGROUND_TRANSFORMERS,
              root
            );
          }
        }
      });

      root.selectEnd();
    });

    setShowLinePlaceholder(true);
    setIsInsertingToEditor(false);
    addNotification({
      type: "success",
      title: `Content brief inserted`,
    });
    setOpen(false);
  };

  const isLoadingAnySection = useMemo(
    () => loadingSections.some((loading) => loading),
    [loadingSections]
  );

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        setOpen(open);
        setSampleTexts([]);
        setSelectedSection(null);
        setGenerated(false);
        setSections(briefSections);
        setLoadingSections(Array(briefSections.length).fill(false));
        setIsPreviewing(false);
        setIsLoading(false);
        setIsInsertingToEditor(false);
      }}
    >
      <DialogTrigger asChild>
        {trigger || (
          <Button variant="outlineBlur" size="xs" className="h-7">
            Generate content brief
          </Button>
        )}
      </DialogTrigger>
      <DialogContent
        className={"flex max-h-[70vh] max-w-[1250px] mx-12 p-0"}
        variant={isPreviewing ? "templates" : "fitContent"}
        containerClassName={isPreviewing ? "p-0" : "px-2"}
        style={{
          padding: "0",
        }}
      >
        <div
          className={cn(
            "w-full flex flex-col",
            isPreviewing && "border-r dark:border-r-zinc-700 w-80"
          )}
        >
          <div
            id="header"
            className={cn(
              "w-full",
              !isPreviewing &&
                "border-b pb-4 border-zinc-200 dark:border-zinc-800"
            )}
          >
            <DialogHeader className="pb-0 flex items-center justify-between">
              <DialogTitle>
                {isPreviewing ? "Preview brief" : "Generate content brief"}
              </DialogTitle>
              {!isPreviewing && (
                <DialogCloseButton
                  close={() => {
                    setOpen(false);
                  }}
                />
              )}
            </DialogHeader>
            <div className="flex flex-col space-y-1 px-4 pt-4">
              <p className="text-xs text-zinc-600 dark:text-zinc-400">
                {isPreviewing
                  ? "Review the following sections to include in your content brief."
                  : "Please select the sections to include in your content brief."}
              </p>
            </div>
          </div>

          <div
            id="scroll"
            className=" text-sm space-y-2 px-4 mt-2 flex-1 overflow-y-auto pb-4"
            ref={scrollRef}
          >
            {sections
              .filter((section) => !isPreviewing || section.selected)
              .map((section, index) => (
                <SectionCard
                  key={section.name}
                  selectedSection={selectedSection}
                  setSelectedSection={setSelectedSection}
                  generated={generated}
                  loadingSections={loadingSections}
                  handleSectionToggle={handleSectionToggle}
                  handleSectionRemove={handleSectionRemove}
                  section={section}
                  index={index}
                  isPreviewing={isPreviewing}
                />
              ))}
          </div>
          <DialogFooter className="px-4 pb-4 justify-between w-full flex sm:justify-between mt-auto border-t pt-4">
            {!isPreviewing ? (
              <>
                <Button
                  variant="outlineBlur"
                  size="xs"
                  onClick={handleSelectAllToggle}
                >
                  {sections.every((section) => section.selected)
                    ? "Unselect all"
                    : "Select all"}
                </Button>
                <Button
                  type="submit"
                  variant="primaryBlur"
                  onClick={handleGenerate}
                  disabled={!sections.some((section) => section.selected)}
                >
                  Generate brief
                </Button>
              </>
            ) : (
              <Button
                variant="outlineBlur"
                className="w-fit"
                startIcon={<TbArrowLeft className="w-4 h-4 mr-2" />}
                onClick={handleBack}
              >
                Back
              </Button>
            )}
          </DialogFooter>
        </div>
        {isPreviewing && (
          <div className="flex-1 ml-4 mr-4 mb-4 space-y-4 flex flex-col overflow-auto">
            {selectedSection !== null && (
              <BriefPreviewEditor
                html={sampleTexts[selectedSection as string] || ""}
                title={selectedSection}
                selectedTemplate={null}
                setOpen={setOpen}
                isLoading={
                  loadingSections[
                    sections.findIndex((s) => s.name === selectedSection)
                  ]
                }
              />
            )}
            <Button
              variant="primaryBlur"
              className="ml-auto w-fit"
              isLoading={isInsertingToEditor || isLoadingAnySection}
              onClick={handleInsertToEditor}
              disabled={isInsertingToEditor || isLoadingAnySection}
            >
              {isLoadingAnySection
                ? "Generating brief"
                : "Insert brief to editor"}
            </Button>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};
