import { useQuery } from "react-query";
import { axios } from "../../../lib/axios";

export type Coupon = {
  amountOff: number;
  created: number;
  currency: string;
  duration: string;
  durationInMonths: number | null;
  id: string;
  livemode: boolean;
  maxRedemptions: number | null;
  metadata: Record<string, unknown>;
  object: string;
  percentOff: number | null;
  redeemBy: number | null;
  timesRedeemed: number;
  valid: boolean;
};

export const getCouponStatus = async (couponId: string): Promise<Coupon> => {
  const response = await axios.post<Coupon>("/stripe/getCouponStatus", {
    coupon_id: couponId,
  });
  return response;
};

export const useGetCouponStatus = (couponId: string, config = {}) => {
  return useQuery(["couponStatus", couponId], () => getCouponStatus(couponId), {
    ...config,
  });
};
